import * as types from './mutation_types'
import axios from 'axios'
const endPoint = '/configuration/permissions/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SHOW] (state, payload) {
      state.item = payload
    },
  },
  actions: {
    fetchItems({commit}) {
      return axios.get(endPoint)
        .then(response =>{
          commit(types.SET, response.data)
          return Promise.resolve()
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({commit}, params) {
      return axios.post(endPoint, params)
        .then(response =>{
          return Promise.resolve(response)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, _}, id) {
      return axios.delete(`${endPoint}${id}`)
        .then(({data}) =>{
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getRolesByPermission({_}, id) {
      return axios.get(`${endPoint}${id}/roles`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    removePermissionFromRole({_}, payload) {
      return axios.delete(`${endPoint}${payload.permissionId}/role/${payload.roleId}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getRolesWithPermissions({commit, _}, roleId) {
      return axios.get(`${endPoint}role/with-permissions/${roleId}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
