import * as types from './mutation_types'
import axios from 'axios';
const endPoint = '/financial/account-statement'
export default {
  namespaced: true,
  state: {
    items: [],
    balance: [],
  },
  getters:{
    balance: state => state.balance,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_BALANCE] (state, payload){
      state.balance = payload
    }
  },
  actions: {
    getBalance({commit}, params) {
      return axios.get(`${endPoint}`, {params: params})
      .then(({data}) =>{
        commit(types.SET_BALANCE, data.data)
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
    },
    getItems({commit}, params) {
      return axios.get(`${endPoint}/items`, {params: params})
      .then(({data}) =>{
        commit(types.SET, data.data)
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
    },
    getReport({}, payload) {
      return axios.get(`${endPoint}/report`, {
        responseType: "blob",
        timeout: 30000,
        params : payload,
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
