import * as types from './mutation_types'
import axios from 'axios'
const endPoint = '/commercial/contract/services/'
export default {
  namespaced: true,
  state: {
    item: [],
  },
  getters:{
    show: state => state.item,
  },
  mutations: {
    [types.SHOW] (state, payload){
      state.item = payload
    },
  },
  actions: {
    getVersionResume({commit}, payload) {
      return axios.get(`${endPoint}version-resume/${payload}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
