import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/cmc'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    carrier_prices: [],
    cmc_prices: [],
    simplified: [],
  },
  getters: {
    show: state => state.item,
    getCmcPrice: state => state.cmc_prices,
    getSimplified: state => state.simplified,
    fetch: state => state.items,
    fetchCarrierPrice: state => state.carrier_prices,
    fetchNextPage: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_PRICE](state, payload) {
      state.cmc_prices = payload
    },
    [types.SET_CARRIER](state, payload) {
      state.carrier_prices = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
  },
  actions: {
    fetchItems({ commit, state }, payload = {}) {
      return axios.get(setUrlWithNextPage(endPoint, payload),
        {params: payload})
        .then(({ data }) => {
          cursorPaginate(state, {data : data.data, next_page: payload.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchCarrierByCmcIdByPlanId({ commit, state }, params) {
      return axios.get(`${endPoint}/carrier/price/plant?cmc_id=${params.cmcId}&company_plant_id=${params.plantId}`)
        .then(({ data }) => {
          commit(types.SET_CARRIER, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchBySupplierIdAndSupplierOriginId({ commit, state }, params) {
      return axios.get(`${endPoint}/supplier/${params.supplierId}/supplier-origin/${params.supplierOriginId}`)
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchPrice({ commit, state }, payload) {
      return axios.get(`${endPoint}/prices/plant/${payload.plantId}/group/${payload.groupId}`, {params: payload})
        .then(({ data }) => {
          commit(types.SET_PRICE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchPriceActive({ commit }) {
      return axios.get(endPoint+'/price/active')
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({ commit, state }, payload) {
      return axios.post(`${endPoint}`, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    addCarrierPrice({ commit, state }, payload) {
      return axios.post(`${endPoint}/carrier/price`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    addPrice({ commit, state }, payload) {
      return axios.post(`${endPoint}/price`, payload)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({ commit }, payload) {
      return axios.put(`${endPoint}/${payload.id}`, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({ commit, dispatch }, id) {
      return axios.get(`${endPoint}/${id}`)
        .then(({ data }) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({ commit, dispatch }, uuid) {
      return axios.delete(`${endPoint}/${uuid}`)
        .then(({ data }) => {
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroyCarrierPrice({ commit, dispatch }, id) {
      return axios.delete(`${endPoint}/carrier/price/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroyPrice({ commit, dispatch }, id) {
      return axios.delete(`${endPoint}/price/${id}`)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getMeasuredUnitBuy({}, payload) {
      return axios.get(`${endPoint}/${payload}/measured-unit-buy`)
        .then(({ data }) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCmcByPlantAndCategory({commit}, params) {
      return axios.get(`${endPoint}/cmc-by-plant-and-category/${params.company_plant_id}/${params.category_id}`)
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getCmcSimplified({commit, state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}/get/simplified`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'simplified')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getStockLocationByPlantAndCategory({}, params) {
      return axios.get(`${endPoint}/stock-location-by-plant-and-category/${params.company_plant_id}/${params.category_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    newFetchItems({ state }, payload = {}) {
      return axios.get(setUrlWithNextPage('/technology/cmc', payload),
        {params: payload})
        .then(({ data }) => {
          cursorPaginate(state, {data : data.data, next_page: payload.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
