import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'

const endPoint = 'operational/stock/movements+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    current: [],
    analyze: [],
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    getCurrent: state => state.current,
    getAnalyze: state => state.analyze,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.DESTROY](state, id) {
      destroy(state, id)
    },
  },
  actions: {
    fetchItems({commit, state}, params) {
      return axios.patch(setUrlWithNextPage(endPoint, params),
        params)
        .then(({data}) => {
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCurrent({commit, state}, params) {
      return axios.patch(setUrlWithNextPage(endPoint + '/current', params),
        params)
        .then(({data}) => {
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'current')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAnalyze({commit, state}, params) {
      return axios.patch(setUrlWithNextPage(endPoint + '/analyze', params),
        params)
        .then(({data}) => {
          cursorPaginate(state, {data : data.data, next_page: params.next_page}, 'analyze')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit}, params) {
      return axios.post(endPoint, params)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit}, params) {
      return axios.put(`${endPoint}/${params.id}`, params)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    replicate({commit}, params) {
      return axios.post(`${endPoint}/replicate`, params)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit}, id) {
      return axios.get(`${endPoint}/${id}`)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    downloadExcel(_, params) {
      return axios.get(`operational/stock/movements/excel`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    remove({commit}, id) {
      return axios.delete(`${endPoint}/${id}`)
        .then(({data}) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
