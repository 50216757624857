import * as types from "./mutation_types";
import axios from "axios";
const endPoint = "/commercial/concrete-price+microservice";
var nextPage = null;
export default {
  namespaced: true,
  state: {
    items: [],
    price_adjustment: {}
  },
  getters: {
    fetch: (state) => state.items,
    getPriceAdjustment: (state) => state.price_adjustment,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_PRICE](state, payload) {
      state.price_adjustment = payload;
    },
    [types.SET_NEXT_PAGE](state, payload) {
      nextPage = payload;
    },
    [types.SET_NEXT_PAGE_DATA](state, payload) {
      state.items = state.items.concat(payload);
    },
  },
  actions: {
    fetchItems({ commit }, payload) {
      return axios
        .get(payload.has_pagination ? nextPage : endPoint, { params: payload })
        .then(({ data }) => {
          payload.has_pagination ? commit(types.SET_NEXT_PAGE_DATA, data.data) : commit(types.SET, data.data);
          commit(types.SET_NEXT_PAGE, data.next_page);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getPriceByPuzlAdjustment({ commit }, params) {
      return axios
        .get(`${endPoint}/puzl-adjustment`, { params: params })
        .then(({ data }) => {
          commit(types.SET_PRICE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
