import * as types from './mutation_types'
import axios from 'axios'
const endPoint = '/financial/bank-billet-remittance'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },    
  },
  actions: {
    fetchItems({commit}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(`${endPoint}`, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    updateWasDownloaded({commit}, payload) {
      return axios.put(`${endPoint}/${payload.id}/was-downloaded/${payload.wasDownloaded}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },    
  }
}
