import axios from 'axios'
import * as types from './mutation_types'
const endPoint = '/company/plant/bank-account/'
export default {
  namespaced: true,
  state: {
    item:{},
    items: [],
  },
  getters:{
    getListBankAccounts: state => state.items,
  },
  mutations: {
    [types.SET_BANK_ACCOUNTS] (state, payload){
      state.items = payload
    },
  },
  actions: {
    getByCompanyPlant({commit}, payload) {
      return axios.get(`${endPoint}by-plant/${payload.company_plant_id}`, {params: payload})
        .then(({data}) =>{
          commit(types.SET_BANK_ACCOUNTS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    destroy({}, payload) {
      return axios.delete(`${endPoint}${payload}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    setDefaultCompanyPlantBankAccount({}, payload) {
      return axios.get(`${endPoint}${payload.bank_account_id}/${payload.company_plant_id}/default`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
