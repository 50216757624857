import * as types from "./mutation_types";
import axios from "axios";
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
const endPoint = "/operational/daily-resume/+microservice";
export default {
  namespaced: true,
  state: {
    services: [],
    schedules: [],
    cards: null,
  },
  getters: {
    getServices: (state) => state.services,
    getSchedules: (state) => state.schedules,
    getGeneralCards: (state) => state.cards,
  },
  mutations: {
    [types.SET_SERVICES](state, payload) {
      state.services = payload;
    },
    [types.SET_SCHEDULES](state, payload) {
      state.schedules = payload;
    },
    [types.SET_GENERAL_CARDS](state, payload) {
      state.cards = payload;
    },
  },
  actions: {
    getGeneralCards({ commit }, payload) {
      return axios
        .get(`${endPoint}general`, { params: payload })
        .then(({ data }) => {
          commit(types.SET_GENERAL_CARDS, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getServices({ commit }, payload) {
      return axios
        .get(`${endPoint}services`, { params: payload })
        .then(({ data }) => {
          commit(types.SET_SERVICES, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchServicesItemsPaginate({state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}services`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'services')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getSchedules({ commit }, payload) {
      return axios
        .get(`${endPoint}schedules`, { params: payload })
        .then(({ data }) => {
          commit(types.SET_SCHEDULES, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchSchedulesItemsPaginate({state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}schedules`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'schedules')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  },
};
