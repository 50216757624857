import * as types from "./mutation_types";
import { destroy, update } from "@/store/baseStore";
import axios from "axios";
const endPoint = "/operational/schedule/service/+microservice";
export default {
  namespaced: true,
  state: {
    item: {},
    items: []
  },
  getters: {
    show: state => state.item
  },
  mutations: {
    [types.DESTROY](state, uuid) {
      destroy(state, uuid);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    }
  },
  actions: {
    destroy({ commit, dispatch }, uuid) {
      return axios
        .delete(endPoint + uuid)
        .then(({ data }) => {
          commit(types.DESTROY, uuid);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      return axios
        .put(endPoint + payload.uuid, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    add({ commit }, payload) {
      return axios
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    setCycle({ commit }, payload) {
      return axios
        .post(endPoint + "cycle", payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getCycle({ commit }, payload) {
      return axios
        .get(endPoint + "cycle/" + payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    addServiceOrder({ commit }, payload) {
      return axios
        .post(endPoint + "order/", payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    cancelServiceOrder({ commit }, payload) {
      return axios
        .post(endPoint + "order/cancel", payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    generateReport({ commit }, payload) {
      return axios
        .get(`${endPoint}order/generate-report/${payload}`, {
          responseType: "blob",
          timeout: 30000,
          params: payload
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    cancelService({ commit }, payload) {
      return axios
        .post(endPoint + "cancel", payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    fillCycle({commit}, payload) {
      return axios
        .post(`${endPoint}cycle/fill/${payload.id}`, payload)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    changePerformedValue({}, payload) {
      return axios
        .post(endPoint + "change-performed-value", payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getPerformedVolume(_, id) {
      return axios
        .get(`${endPoint}performed-volume/${id}`) 
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
  }
};
