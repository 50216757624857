import * as types from './mutation_types'
import { cursorPaginate, setUrlWithNextPage } from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/administrative/horary-resume'

export default {
    namespaced: true,
    state: {
        items: [],
        widgets: {},
    },
    getters: {
        fetch: state => state.items,
        fetchWidgets: state => state.widgets,
    },
    mutations: {
        [types.SET_HORARYS](state, payload) {
            state.items = payload
        },
        [types.SET_WIDGETS](state, payload) {
            state.widgets = payload
        },
    },
    actions: {
        fetchHorarysItemsPaginate({ state }, params) {
            return axios.get(setUrlWithNextPage(`${endPoint}/horarys`, params),
                { params: params })
                .then(({ data }) => {
                    cursorPaginate(state, { data: data.data, next_page: params.next_page })
                    return Promise.resolve(data)
                }).catch(error => {
                    return Promise.reject(error)
                })
        },
        getWidgets({ commit }, params) {
            return axios.get(`${endPoint}/widgets`, { params: params })
                .then(({ data }) => {
                    commit(types.SET_WIDGETS, data.data)
                    return Promise.resolve(data)
                }).catch(error => {
                    return Promise.reject(error)
                })
        },
    }
}
