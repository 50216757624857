import axios from 'axios'
const endPoint = 'operational/schedule/schedule-travel/molding/+microservice'
export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    store({commit, state}, params) {
      return axios.post(`${endPoint}`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
