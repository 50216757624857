import axios from 'axios'
import * as types from './mutation_types'
import {destroy, update, cursorPaginate,setUrlWithNextPage, formatResponseData} from '@/store/baseStore'

const endPoint = '/purchase'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
  },
  actions: {
    add({ commit }, params) {
      return axios.post(`${endPoint}/purchase-category`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    fetchItemsPaginate({state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}/purchase-categories`, params),
      {params: params})
        .then(({data}) => {
          const formattedData = formatResponseData(data);
          cursorPaginate(state, {data : formattedData.data, next_page: params.page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit}, id) {
      return axios.delete(`${endPoint}/purchase-categories/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({ commit }, params) {
      // Realiza a solicitação
      return axios.put(`${endPoint}/purchase-categories/${params.id}`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    show({commit}, id) {
      return axios.get(`${endPoint}/purchase_request/${id}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
