import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/moldingcapping/'
export default {
  namespaced: true,
  state: {
    item: [],
  },
  getters:{
    show: state => state.item,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
  },
  actions: {
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, payload) {
      return axios.post(`${endPoint}molding`, payload)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
