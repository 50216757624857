import * as types from './mutation_types'
import axios from 'axios'

const endPoint = '/configuration/entity-history/'

export default {
  namespaced: true,
  state: {
    history: null,
    entityBillReceiveItems:[],
  },
  getters: {
    getHistory: state => state.history,
    getEntityBillReceiveItems:state => state.entityBillReceiveItems,
  },
  mutations: {
    [types.SET](state, payload) {
      state.history = payload
    },
    [types.SET_ENTITY_BILL_RECEIVE_ITEMS](state, payload) {
      state.entityBillReceiveItems = payload
    },
  },
  actions: {
    getHistory({ commit }, payload) {
      return axios.get(`${endPoint}${payload.id}`, {params: payload})
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getEntityBillReceiveItems({ commit }, payload) {
      return axios.get(`${endPoint}${payload.id}/entity-bill-receive-items`, {params: payload})
        .then(({ data }) => {
          commit(types.SET_ENTITY_BILL_RECEIVE_ITEMS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
