import axios from 'axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    setupWhatsappApi({ }, params) {
      return axios.post('/setup/store+whatsapp', params, {
        headers: {
          "Content-type": "application/json"
        }
      }).then(({ data }) => {
        return Promise.resolve(data);
      }).catch(error => {
        return Promise.reject(error);
      })
    },
    sendCustomerService({}, params) {
      return axios.post('/service+whatsapp', params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(({data}) => {
        return Promise.resolve(data);
      }).catch(error => {
        return Promise.reject(error);
      })
    }
  }
}
