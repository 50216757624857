import * as types from "./mutation_types";
import axios from "axios";
import { destroy, cursorPaginate, setUrlWithNextPage } from "@/store/baseStore";
const endPoint = "/configuration/entry-launch/external/";
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
  },
  actions: {
    fetch({ commit, state }, params) {
      return axios
        .get(setUrlWithNextPage(endPoint, params), { params: params })
        .then(({ data }) => {
          cursorPaginate(state, { data: data.data, next_page: params.next_page });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({commit}, payload) {
      return axios
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.DESTROY, payload.id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    addMany({}, payload) {
      return axios
        .post(`${endPoint}many`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getDetails({}, payload) {
      return axios
        .get(`${endPoint}details/${payload}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getReportExcel(_, params) {
      return axios.get(endPoint + 'report-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  },
};
