<template>
  <div>
    <multi-filter ref="multi-filter" @fetch="init" :filter="filter" />
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>
      <!-- Dados vazios -->
      <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton" />
      <div class="row card-wrapper" v-if="$_items.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(item, index) in $_items" :key="index">
          <card>
            <!-- Card header -->
            <div class="row align-items-center mb-1">
              <div class="col-9 justify-content-center pr-0">
                <h3 class="mb-0">{{ item.name }}</h3>
              </div>
              <div class="col-3 text-right pl-0">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click="handleModalEdit(item.id)">
                    <i class="fas fa-edit text-warning main-icon-menu"></i>
                    Editar
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleDelete(item.id)">
                    <i class="fas fa-times text-danger main-icon-menu"></i>
                    Excluir
                  </a>
                </base-dropdown>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <ModalEdit ref="modalEdit" />
    <LoadingPagination :show="loading && !loadingSkeleton" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEdit from "./_ModalEdit";
import MultiFilter from "@/components/Utils/MultiFilter.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";

export default {
  name: "List",
  mixins: [cursorPaginate],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    ModalEdit,
    MultiFilter,
    LoadingPagination,
  },
  data() {
    return {
      loadingSkeleton: true,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      $_items: "stockLocation/fetch",
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("stockLocation/fetch", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store
            .dispatch("stockLocation/destroy", id)
            .then((response) => {
              this.$notify({
                type: response.error_type,
                message: response.message,
              });
              loader.hide();
            })
            .catch((error) => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message,
              });
              loader.hide();
            });
        }
      });
    },
    handleModalEdit(id) {
      this.$refs.modalEdit.openModal(id);
    },
    clearFilters() {
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null,
      };
      this.init();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}
</style>
