import * as types from './mutation_types'
import {destroy, update, cursorPaginate,setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/operational/schedule/+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit,state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
      {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    reschedule({commit, state}, payload) {
      return axios.post(endPoint+'reschedule/'+payload.uuid, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    getHistories({commit}, payload) {
      return axios.get(endPoint+'schedule-history/'+payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return axios.put(endPoint+payload.uuid, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(endPoint+uuid)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    forceFinish({commit, state}, payload) {
      return axios.get(endPoint+'force-finished/'+payload.uuid)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    recoveryForceFinish({commit, state}, payload) {
      return axios.get(endPoint+'recovery-force-finished/'+payload.uuid)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    forceUpdate({commit}, params) {
      commit(types.UPDATE, params)
    },
    releaseTravels({commit}, payload) {
      return axios.get(`${endPoint}${payload.uuid}/release-travel-index/${payload.index}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    getAllByContractProposal({commit,state}, payload) {
      return axios.get(setUrlWithNextPage(`${endPoint}contract-proposal/${payload.contract_proposal_uuid}`, payload),
        {params: payload})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: payload.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    authorizeRequest({commit}, payload) {
      return axios.get(`${endPoint}authorize-request/${payload}`)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    lastConcretingDate({commit, state}, contract_uuid) {
      return axios.get(`${endPoint}contract-proposal/${contract_uuid}/last-concreting-date`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    getLastConcretingDateByEntity({commit}, payload) {
      return axios.get(`${endPoint}entity/${payload}/last-concreting-date`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    postSetFeatureLevel({commit}, params) {
      return axios.post(`${endPoint}formulation/set-feature-level`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateServiceType({_}, params) {
      return axios.put(`${endPoint}service-type/`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchApprovalTechnicalItems({state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}technology/approval-technical/`, params),
      {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    approvalManyTechnicalItems({}, params) {
      return axios.post(`${endPoint}technology/approval-technical/`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    disapproveTechnicalItem({}, id) {
      return axios.post(`${endPoint}technology/disapprove-technical/${id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
