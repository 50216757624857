import * as types from './entity/mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/recipenet'
export default {
  namespaced: true,
  state: {
    item: [],
  },
  getters:{
    show: state => state.item,
  },
  mutations: {
    [types.SET] (state, payload){
      state.item = payload
    },
  },
  actions: {
    fetch({commit}, payload) {
      return axios.post(`${endPoint}/ein`, payload)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
