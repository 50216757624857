import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'

const endPoint = '/commercial/contract-proposal/formulations+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    activeItems: [],
    item: null,
    mix_designs: [],
    concreted_piece: {},
    minimum_cost: {},
    cycle_values: {},
    situation: {}
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    getSituation: state => state.situation,
    getMixDesigns: state => state.mix_designs,
    getMinimumCost: state => state.minimum_cost,
    getConcretedPiece: state => state.concreted_piece,
    getCycleValues: state => state.cycle_values,
    activeItems(state) {
      return state.items.filter(item => item.status === true).sort((a, b) => a.name > b.name)
    }
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_MIX_DESIGNS](state, payload) {
      state.mix_designs = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
    [types.FETCH_CONCRETED_PIECE](state, payload) {
      state.concreted_piece = payload
    },
    [types.FETCH_MINIMUM_COST](state, payload) {
      state.minimum_cost = payload
    },
    [types.FETCH_CYCLE_VALUES](state, payload) {
      state.cycle_values = payload
    },
    [types.FETCH_SITUATION](state, payload) {
      state.situation = payload
    },
  },
  actions: {
    fetchItem({commit}, params) {
      return axios.get(endPoint+'/formulations/'+params.uuid, {params: params})
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchBasic({commit}, params) {
      return axios.get(`${endPoint}/only-formulations`, {params: params})
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchMixDesigns({commit}, params) {
      return axios.get(endPoint + '/mix-designs', {params: params})
        .then(({data}) => {
          commit(types.SET_MIX_DESIGNS, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postAddConcretePiece({commit, state}, params) {
      return axios.post(endPoint+'/add-concrete-piece', params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postUpdateThecnicalData({commit, state}, params) {
      return axios.post(endPoint+'/add-thecnical-data', params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    addNewValidity({commit, state}, payload) {
      return axios.post(endPoint+'/new-validity', payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit, state}, payload) {
      return axios.put(`${endPoint}/formulation/${payload.uuid}`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/formulation/${uuid}`)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(`${endPoint}/${uuid}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getConcretedPieces({commit, dispatch}, params) {
      return axios.get(`${endPoint}/concreted-piece-percentage/`, {params: params})
        .then(({data}) => {
          commit(types.FETCH_CONCRETED_PIECE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getMixParameters({commit, dispatch}, params) {
      return axios.get(`/commercial/concrete-price/minimum-costs/minimum-cost-by-plant/${params.id}`)
        .then(({data}) => {
          commit(types.FETCH_MINIMUM_COST, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getCycleValues({commit, dispatch}, params) {
      return axios.get(`${endPoint}/cycle-values`, {params: params})
        .then(({data}) => {
          commit(types.FETCH_CYCLE_VALUES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    createSituation({commit, state}, payload) {
      return axios.post(endPoint+'/situation', payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    showSituation({commit, state}, uuid) {
      return axios.get(endPoint+'/situation/'+uuid,)
        .then(({data}) => {
          commit(types.FETCH_SITUATION, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    setStatus({commit, state}, payload) {
      return axios.put(endPoint+'/set-status/'+payload.uuid, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getResume({commit}, payload) {
      return axios.get(`${endPoint}/resume/${payload}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getFormulationBilled({commit}, uuid) {
      return axios.get(`${endPoint}/has-billed/${uuid}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postChangePrice({commit}, params) {
      return axios.post(`${endPoint}/change-price/${params.uuid}`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getByPiece({commit}, params) {
      return axios.get(endPoint+'/formulations/'+params.uuid+'/piece/'+params.piece_id)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    changeFeatureLevel(_, params) {
      return axios.post(`${endPoint}/change-level-feature`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    }
  },
}
