import * as types from './mutation_types'
import {destroy, update, cursorPaginate,setUrlWithNextPage, formatResponseData} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/financial/finances/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, id){
      destroy(state,id)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchItems({state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
      {params: params})
        .then(({data}) => {
          const formattedData = formatResponseData(data);
          cursorPaginate(state, {data : formattedData.data, next_page: params.page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit}, params) {
      return axios.post(endPoint, params)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, params) {
      return axios.put(endPoint+params.id, params)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit}, id) {
      return axios.delete(endPoint+id)
        .then(({data}) =>{
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
