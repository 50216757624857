import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/configuration/equipment/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    itemCountEquipments: [],
    itemsEquipmentsByCategory: [],
  },
  getters:{
    show: state => state.item,
    showCountEquipments: state => state.itemCountEquipments,
    fetch: state => state.items,
    fetchEquipmentsByCategory: state => state.itemsEquipmentsByCategory,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.SHOW_COUNT_EQUIPMENTS] (state, payload){
      state.itemCountEquipments = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
    [types.SET_EQUIPMENTS_BY_CATEGORY] (state, payload){
      state.itemsEquipmentsByCategory = payload
    },
  },
  actions: {
    fetchItems({commit}) {
      return axios.get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return axios.put(`${endPoint}${payload.uuid}`,  payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(endPoint+uuid)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    allocation({commit}, payload) {
      return axios.put(`${endPoint}allocation/${payload.equipment_uuid}`,  payload)
          .then(({data}) =>{
            commit(types.UPDATE, data.data)
            return Promise.resolve(data)
          }).catch(error =>{
            return Promise.reject(error)
          })
    },
    getByCompanyPlant({commit}, payload) {
      return axios.get(`${endPoint}company-plant/${payload}`)
          .then(({data}) =>{
            commit(types.SET, data.data)
            return Promise.resolve(data)
          }).catch(error =>{
            return Promise.reject(error)
          })
    },
    getByCompanyPlantAndService({commit}, params) {
      return axios.get(`${endPoint}company-plant/${params.filter.company_plant_uuid}`, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    buySell({commit}, payload) {
      return axios.post(`${endPoint}${payload.vehicle_uuid}/buysale`,  payload)
          .then(({data}) =>{
            commit(types.UPDATE, data.data)
            return Promise.resolve(data)
          }).catch(error =>{
            return Promise.reject(error)
          })
    },
    getEquipmentByCategory({commit}, payload) {
      return axios.get(`${endPoint}equipment-category/${payload}`)
        .then(({data}) =>{
          commit(types.SET_EQUIPMENTS_BY_CATEGORY, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getCountEquipments({commit, dispatch}) {
      return axios.get(`${endPoint}total-equipments`)
        .then(({data}) =>{
          commit(types.SHOW_COUNT_EQUIPMENTS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    storeThirdPartyResponsible({commit}, payload) {
      return axios.put(`${endPoint}store-third-party-responsible/${payload.equipment_id}/${payload.entity_id}`,  payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    updateSituation({commit}, params) {
      return axios.put(`${endPoint}update-situation/${params.equipmentId}/${params.situationId}`)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
