import * as types from './mutation_types'
import axios from 'axios'
const endPoint = '/measuredunit/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    measured_unit_buy (state) {
      return state.items.filter(item => item.is_buy === true)
    },
    measured_unit_dosage (state) {
      return state.items.filter(item => item.is_dosage === true)
    },
    measured_unit_stock (state) {
      return state.items.filter(item => item.is_stock === true)
    },
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
  },
  actions: {
    fetchItems({commit}) {
      return axios.get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
