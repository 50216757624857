<template>
  <div
    ref="component"
    class="atomic-dropdown"
    :class="variant"
  >
    <div
      class="atomic-dropdown-selected"
      @click="onToggleDropdownClick"
      :class="{ 'select-arrow-active': isOpen }"
    >
      <img v-if="icon" :src="icon" width="20">
      <!-- localValue != null, em alguns casos são passados 0 (Zero), como na tela de operações financeiras -->
      <!-- Opção 'PENDENTE' vem como valor 0 (Zero)  -->
      <input
        type="text"
        :style="'width: ' + inputSize + 'px'"
        @keyup.enter="onSelectItemClick(filteredItems[0]?.id)"
        @keyup.esc="abortSearch"
        @input="isOpen = true"
        v-model="searchString"
        :placeholder="localValueName"
        :class="{ 'new-default-gray-font': localValue != null }"
        :readonly="!props.isSearchable">
      <img
        v-if="props.clearable"
        style="margin-left: auto;"
        @click.prevent="onClearSelectionClick"
        src="/img/icons/icons8/ios/close-v1_gray.png"
        width="15"
      >
    </div>
    <div
      class="dropdown-items"
      ref="itemsPopup"
      :style="{ left: isOverflowingScreen }"
      :class="{ 'is-open': isOpen }">
      <div
        v-for="filteredItem in filteredItems"
        :key="filteredItem.id"
        @click="onSelectItemClick(filteredItem.id)"
      >
        <img
          v-if="filteredItem.icon"
          :src="filteredItem.icon"
          width="15"
        >
        {{ filteredItem.name }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch, computed, onMounted, onUnmounted } from 'vue';

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {Array|Number|String|null} value
 * @property {Array<{id: number, name: string, selected_name: string}>, icon: string} items
 * @property {Boolean} isMultiple TODO: Seleção múltipla não está pronta para uso, está em construção
 * @property {Boolean} isSearchable - Quando true será pesquisável
 * @property {String} icon
 * @property {Boolean} clearable
 * @property {String} placeholder
 * @property {String} variant
 */
/** @type {Props} */
const props = defineProps({
  value: {
    type: [Array, Number, String, null],
    default: () => null,
    required: false,
  },
  items: {
    type: Array,
    default: () => [],
    required: true,
  },
  isMultiple: {
    type: Boolean,
    default: () => false,
    required: false,
  },
  isSearchable: {
    type: Boolean,
    default: () => false,
    required: false
  },
  icon: {
    type: String,
    default: () => "",
    required: false,
  },
  clearable: {
    type: Boolean,
    default: () => false,
    required: false,
  },
  placeholder: {
    type: String,
    default: () => 'Selecione',
    required: false,
  },
  variant: {
    type: String,
    default: "",
    required: false,
  },
});

/** Eventos emitidos */
const emit = defineEmits([
  'input',
  'update:items',
  'onToggleDropdownClick',
  'onClearSelectionClick',
  'onSelectItemClick',
]);

/** String de pesquisa interna do select **/
const searchString = ref("");

/** Referência ao card com itens do AppSelect **/
const itemsPopup = ref(null);

/** Filtro local de value */
const localValue = ref(props.value);

/** Filtro local de itens */
const localItems = ref(props.items);

/** span usado para reajustar largura do input **/
const spanId = ref('width-' + Math.random().toString(36).substr(2, 9));

const filteredItems = computed(() => {
  if(searchString.value){
    const newItems = [];

    props.items.map(item => {
      if(item.name.toLowerCase().includes(searchString.value.toLowerCase())
        || item.selected_name.toLowerCase().includes(searchString.value.toLowerCase()))
      {
        newItems.push(item);
      }
    });

    return newItems;
  }

  return localItems.value;
});

/** Dropdown aberto ou fechado */
const isOpen = ref(false);

/** Referência do componente */
const component = ref(null);

/** Sincronização de localValue e value */
watch(() => props.value, (newValue) => localValue.value = newValue);
watch(localValue, (newValue) => emit('input', newValue));

/** Sincronização de localItems e items */
watch(() => props.items, (newValue) => localItems.value = newValue);
watch(localItems, (newValue) => emit('update:items', newValue));

/** Nome computado do valor local */
const localValueName = computed(() => {
  if (localValue.value === null || localValue.value === undefined) {
    return props.placeholder;
  }

  // Seleção múltipla
  if (props.isMultiple) {
    const foundItems = localItems.value.filter((item) => (localValue.value || []).includes(item.id));
    if (foundItems.length > 0) {
      return foundItems.map(item => item.selected_name ?? item.name).join(', ');
    }
    return props.placeholder;
  }

  // Seleção única
  const found = localItems.value.find((item) => item.id == localValue.value);
  return found
    ? (found.selected_name ?? found.name)
    : props.placeholder;
});

const inputSize = computed(() => {
  let span = document.getElementById(spanId.value);
  if (!span) {
    span = document.createElement('span');
    span.id = spanId.value;
    span.style.fontSize = '0.9rem';
    span.style.visibility = 'hidden';
    span.style.position = 'absolute';
    span.style.whiteSpace = 'nowrap';
    document.body.appendChild(span);
  }

  span.innerText = localValueName.value;
  return span.offsetWidth + 5;
});

const isOverflowingScreen = computed(() => {
  if(itemsPopup.value){
    const div = itemsPopup.value;
    const rect = div.getBoundingClientRect();

    if (rect.right > window.innerWidth) {
      return 'unset';
    }
  }

  return '0';
});

function onToggleDropdownClick() {
  isOpen.value = !isOpen.value;
  emit("onToggleDropdownClick");
};

function onClearSelectionClick() {
  localValue.value = props.isMultiple ? [] : null;
  isOpen.value = !isOpen.value;
  searchString.value = "";
  setTimeout(() => emit("onClearSelectionClick"), 100);
};

function onSelectItemClick(id){
  if (props.isMultiple) {
    localValue.value.push(id);
  } else {
    localValue.value = id;
  }
  abortSearch();
  setTimeout(() => emit("onSelectItemClick", id), 100);
};

/**
 * Manipula o clique fora do componente e fecha o dropdown se estiver aberto.
 *
 * @param {MouseEvent} event - O evento de clique.
 * @returns {void}
 */
function handleClickOutside(event) {
  if (component.value && !component.value.contains(event.target)) {
    abortSearch();
  }
};

function abortSearch() {
  isOpen.value = false;
  searchString.value = "";
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style scoped src="./selectStyle.css"></style>
