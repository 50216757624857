import * as types from "../mutation_types";
import {
  destroy,
  update,
  cursorPaginate,
  setUrlWithNextPage,
} from "@/store/baseStore";
import axios from "axios";
const endPoint = "/service/agenda+microservice";
export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
  },
  actions: {
    fetch({ commit }, filters) {
      return axios
        .get(endPoint, { params: filters })
        .then(({ data }) => {
          commit(types.SET, data.data)
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
