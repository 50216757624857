import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
import {SET_FORMULATIONS, SET_FORMULATIONS_ADJUSTMENTS} from "./mutation_types";
const endPoint = '/adjustment/contract-schedule-adjustment+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    formulations: [],
    formulation: [],
    formulation_adjustments: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    fetchFormulations: state => state.formulations,
    formulation: state => state.formulation,
    fetchFormulationAdjustments: state => state.formulation_adjustments,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_FORMULATIONS_ADJUSTMENTS] (state, payload){
      state.formulation_adjustments = payload
    },
    [types.SET_FORMULATIONS] (state, payload){
      state.formulations = payload
    },
    [types.SET_GROUP_CATEGORY] (state, payload){
      state.groups = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.SHOW_FORMULATION] (state, payload){
      state.formulation = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchAdjustmentsItems({commit, state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}/adjustment-formulation/${params.uuid}`, params),
      {params: params}).then(({data}) => {
          cursorPaginate(state, {data : data.data, next_page: params.next_page},'formulation_adjustments')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getFormulations({commit, state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}/contract-proposal-formulations`, params),
        {params: params}).then(({data}) => {
          cursorPaginate(state, {data : data.data, next_page: params.next_page},'formulations')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return axios.put(`${endPoint}/${payload.uuid}`, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    showFormulation({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/formulation/${uuid}`)
        .then(({data}) =>{
          commit(types.SHOW_FORMULATION, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
