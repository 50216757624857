import * as types from "./mutation_types";
import axios from "axios";
import { destroy } from "@/store/baseStore";
const endPoint = "/configuration/task-attachments";

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    show: (state) => state.items,
  },
  mutations: {
    [types.SHOW](state, payload) {
      state.items = payload;
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
  },
  actions: {
    getByTaskId({ commit }, taskId) {
      return axios
        .get(`${endPoint}/${taskId}`)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, id) {
      return axios
        .delete(`${endPoint}/${id}`)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, params) {
      let formData = new FormData();
      formData.append("task_id", params.taskId);
      params.form_data.forEach((el) => {
        formData.append(`files[${el.index}]`, el.file);
      });
      return axios
        .post(`${endPoint}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
