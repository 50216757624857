import * as types from './mutation_types'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/administrative/entity-wallet'

export default {
  namespaced: true,
  state: {
    items: [],
    months: [],
    widgets: {},
  },
  getters:{
    fetch: state => state.items,
    getWidgets: state => state.widgets,
  },
  mutations: {
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
  },
  actions: {
    fetch({commit, state}, params) {
      return axios.patch(setUrlWithNextPage(`${endPoint}`, params),
        params)
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'items')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchMonths({commit, state}, params) {
      return axios.patch(setUrlWithNextPage(`${endPoint}/months`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'months')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getWidgets({commit}, params) {
      return axios.get(`${endPoint}/widgets`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
