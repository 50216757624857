import * as types from './mutation_types'
import axios from 'axios'
const endPoint = '/operational/schedule/travel-distribution+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
  },
  actions: {
    fetchItems({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
