import * as types from './mutation_types'
import axios from 'axios'
import {cursorPaginate, setUrlWithNextPage} from "@/store/baseStore";
import notifications from '/public/notification.json'

const endPoint = '/notification'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    getNotifications: state => state.items,
    getTypes: () => notifications,
  },
  mutations: {
    [types.SET_NOTIFICATIONS](state, payload) {
      state.notifications = payload
    },
  },
  actions: {
    getNotificationsByNotifiable({commit, state}, params) {
      return axios
        .get(setUrlWithNextPage(`${endPoint}/${params.notifiable_id}`, params), {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    putReadNotification({commit}, notification_id) {
      return axios.put(`${endPoint}/read/${notification_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    putReadAllNotifications({commit}, params) {
      return axios.post(`${endPoint}/read-all`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateReadAt({_}, id) {
      return axios.put(`${endPoint}/update-read-at/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    }
  }
}
