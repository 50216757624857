import * as types from "./mutation_types";
import axios from "axios";
import { destroy, update } from "@/store/baseStore";
import {
  cursorPaginate,
  setUrlWithNextPage,
  formatResponseData,
} from "@/store/baseStore";
const endPoint = "/configuration/accounting-chart-accounts/";

export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    grouped: [],
    ordered_code: null,
    last_code: null,
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
    getGrouped: (state) => state.grouped,
    getOrderedCode: (state) => state.ordered_code,
    getLastCode: (state) => state.last_code,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.SET_GROUPED](state, payload) {
      state.grouped = payload;
    },
    [types.SET_ORDERED_CODE](state, payload) {
      state.ordered_code = payload;
    },
    [types.SET_LAST_CODE](state, payload) {
      state.last_code = payload;
    },
  },
  actions: {
    fetchItems({ commit }) {
      return axios
        .get(endPoint)
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getGrouped({ commit }) {
      return axios
        .get(endPoint + "list-grouped/list")
        .then(({ data }) => {
          commit(types.SET_GROUPED, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getLastCode({ commit }, params) {
      return axios
        .get(endPoint + "get-last-code/" + params.uuid)
        .then(({ data }) => {
          commit(types.SET_LAST_CODE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getOrderedCode({ commit }, params) {
      return axios
        .get(endPoint + "get-code-realtime/" + params.uuid + "/" + params.code)
        .then(({ data }) => {
          commit(types.SET_ORDERED_CODE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, payload) {
      return axios
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ _ }, params) {
      return axios
        .put(`${endPoint}${params.uuid}`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit }, uuid) {
      return axios
        .get(endPoint + uuid)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ _ }, uuid) {
      return axios
        .delete(endPoint + uuid)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateDreChildrensTrue({ _ }, uuid) {
      return axios
        .put(endPoint + "update-dre-childrens-true/" + uuid)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
