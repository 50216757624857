import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'

const endPoint = '/financial/bank-reconciliation'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.PUSH](state, payload) {
      state.items.push(payload)
    },
    [types.SHOW](state, payload) {
      state.item = payload
    },
    [types.DESTROY](state, id) {
      destroy(state, id)
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(`${endPoint}`, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return axios.put(`${endPoint}/${payload.id}`, payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({commit}, id) {
      return axios.get(`${endPoint}/${id}`)
        .then(({data}) => {
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, id) {
      return axios.delete(`${endPoint}/${id}`)
        .then(({data}) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    insertByOfxFile({commit, state}, payload) {
      let formData = new FormData();
      formData.append('file', payload.ofx_file);
      formData.append('bank_account_id', payload.bank_account_id);
      return axios.post(`${endPoint}/insert-by-ofx-file`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postAutoConciliation({undefined}, id) {
      return axios.post(`${endPoint}/${id}/auto-conciliation`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    postAutoAssociation({undefined}, params) {
      return axios.post(`${endPoint}/${params.bank_reconciliation_id}/auto-association`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    /**
     * Alterar status de BankReconciliation
     * @param {object} payload {id: int, status: int}
     * @returns {object}
     */
    changeStatus({}, payload) {
      return axios.put(`${endPoint}/change-status/${payload.id}`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
