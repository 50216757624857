import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'

const endPoint = 'operational/stock/resume/mcc+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    items_by_code: [],
    adjustments: [],
    mcc_adjustments: [],
    widgets: {},
  },
  getters: {
    fetch: state => state.items,
    fetchByCode: state => state.items_by_code,
    fetchAdjustments: state => state.adjustments,
    fetchMccAdjustments: state => state.mcc_adjustments,
    fetchWidgets: state => state.widgets,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_WIDGETS](state, payload) {
      state.widgets = payload
    },
    [types.SET_BY_CODE](state, payload) {
      state.items_by_code = payload
    },
    [types.SET_ADJUSTMENTS](state, payload) {
      state.adjustments = payload.data
    },
    [types.SET_MCC_ADJUSTMENTS](state, payload) {
      state.mcc_adjustments = payload.data
    },
  },
  actions: {
    getWidgets({commit}, params) {
      return axios.get(`${endPoint}/widgets`, {params: params})
        .then(({data}) => {
          commit(types.SET_WIDGETS, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchItemsByCode({commit}, params) {
      return axios.get(`${endPoint}/by-code`, {params: params})
        .then(({data}) => {
          commit(types.SET_BY_CODE, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchAdjustments({commit}, params) {
      return axios.get(`${endPoint}/adjustments`, {params: params})
        .then(({data}) => {
          commit(types.SET_ADJUSTMENTS, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchMccAdjustments({commit}, params) {
      return axios.get(`${endPoint}/mcc-adjustments`, {params: params})
        .then(({data}) => {
          commit(types.SET_MCC_ADJUSTMENTS, data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
