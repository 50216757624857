<template>
  <modal :show.sync="modal.create">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form class="needs-validation p-2 mt-2" @submit.prevent="handleSubmit(update)" autocomplete="off">
        <!-- Nome -->
        <div class="form-group row m-0 p-0 mb-1">
          <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
            Nome
            <span class="text-danger mr-2">&nbsp;*</span>
          </label>
          <div class="col-md-6">
            <validation-provider rules="required">
              <base-input input-group-classes="input-group-sm">
                <input v-model="$_payload.name" type="text" class="form-control form-control-sm" maxlength="80" />
              </base-input>
            </validation-provider>
          </div>
        </div>
        <div class="modal-footer">
          <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
          <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar </base-button>
        </div>
      </form>
    </validation-observer>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { formatErrorValidation } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";

export default {
  name: "ModalEdit",
  components: { PuzlSelect },
  data() {
    return {
      modal: {
        title: "Local de Estoque",
        create: false,
      },
      loadingStore: false,
    };
  },
  computed: {
    ...mapGetters({
      $_payload: "stockLocation/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal(id) {
      let loader = this.$loading.show();
      this.$store.dispatch("stockLocation/show", id).then((response) => {
        loader.hide();
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        this.modal.create = true;
      });
    },
    update() {
      this.loadingStore = true;
      this.$store
        .dispatch("stockLocation/update", this.$_payload)
        .then((response) => {
          this.loadingStore = false;
          this.modal.create = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
  },
};
</script>
