import * as types from './mutation_types'
import axios from 'axios'
const endPoint = '/dosage-location'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    dosage_locations: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
  },
  actions: {
    fetchItems({commit}) {
      return axios.get(`${endPoint}`)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
