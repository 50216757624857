import * as types from './mutation_types'
import axios from 'axios'
const endPoint = '/financial/bank-account/account-closing-history'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.unshift(payload)
    },
  },
  actions: {
    getByBankAccountId({commit}, params) {
      return axios.get(`${endPoint}/${params.bankAccountId}`, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        });
    },
    add({commit}, payload) {
      return axios.post(`${endPoint}`, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        });
    },
    reopenAccount({}, payload) {
      return axios.put(`${endPoint}/${payload.bankAccountId}/${payload.closure}`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        });
    },
    isBankingAccountClosed({}, payload){
      return axios.get(`${endPoint}/${payload.bankAccountId}/has-closed-account-greater-than/${payload.closure}`, payload)
       .then(({data}) =>{
         return Promise.resolve(data)
       }).catch(error =>{
         return Promise.reject(error)
       });
   }
  }
}
