import axios from 'axios'
import * as types from './mutation_types'
import {destroy, update, cursorPaginate,setUrlWithNextPage, formatResponseData} from '@/store/baseStore'

const endPoint = '/purchase'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
  },
  actions: {
    fetchItemsPaginate({state}, params) {
      return axios.get(`${endPoint}/purchase-budgets`, {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: formatResponseData(data).data, next_page: params.page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    addEntityBudget({ commit }, params) {
      return axios.post(`${endPoint}/purchase-entity-budget`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    fetchEntityBudgets({commit, state}, params) {
      return axios.get(`${endPoint}/purchase-entity-budget`, { params: params })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({ commit }, params) {
      // Realiza a solicitação
      return axios.post(`${endPoint}/purchase-entity-budget/${params.id}`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    remove({commit}, id) {
      return axios.delete(`${endPoint}/purchase-entity-budget/${id}`)
        .then(({data}) => {
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    // Entidade
    login({commit}, payload){
      return axios.post(`${endPoint}/entity-purchase-budget/login`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    logout({commit}, token){
      return axios.post(`${endPoint}/entity-purchase-budget/logout/${token}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getEntityPurchaseBudget({}, token) {
      return axios.get(`${endPoint}/entity-purchase-budget/${token}`)
        .then(({data}) => {
          const formattedData = formatResponseData(data);
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateEntityBudget({ commit }, params) {
      // Realiza a solicitação
      return axios.post(`${endPoint}/entity-purchase-budget/${params.token}`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
  
  }
}
