import * as types from './mutation_types'
import {destroy, update, cursorPaginate} from '@/store/baseStore'
import axios from 'axios'

const endPoint = '/commercial/contract-proposal'
export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    add({commit, state}, payload) {
      return axios.post( `${endPoint}/proposal-terms`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({}) {
      return axios.get(`${endPoint}/proposal-terms`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
