import axios from 'axios'

const endPoint = '/commercial'
export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
    update({commit, state}, payload) {
      return axios.post( `${endPoint}/digital-signature-configuration`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    show({}) {
      return axios.get(`${endPoint}/digital-signature-configuration`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    updateDefaultSignatory({commit, state}, payload) {
      return axios.post( `${endPoint}/digital-signature-configuration/default-signatory-company-plant`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    showDefaultSignatory({ state }, params = {}) {
      return axios.get(`${endPoint}/digital-signature-configuration/default-signatory-company-plant`, { params })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
