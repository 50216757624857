import * as types from './mutation_types'
import {cursorPaginate, formatResponseData} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/financial/finance-installments/'
const changeStatusEndPoint = '/financial/finances/installment/change-status'
const historyEndPoint = '/financial/finances/installment/history/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
  },
  actions: {
    fetchItems({state}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: formatResponseData(data).data, next_page: params.page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    changeStatus({}, params) {
      return axios.put(changeStatusEndPoint, params)
        .then(({data}) =>{
          return Promise.resolve(data);
        }).catch(error =>{
          return Promise.reject(error);
      })
    },
    getHistory({}, id) {
      return axios.get(historyEndPoint+id)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
