import * as types from "./mutation_types";
import { changeTravelChart, changeCycleChart, changeMixedChart } from "./charts";
import axios from "axios";
const endPoint = "/operational/dashboard/+microservice";
const defaultChartType = "constructions";
const defaultMixedType = "equipments";
export default {
  namespaced: true,
  state: {
    item: {},
    travelChartData: {},
    cycleChartData: {},
    mixedChartData:{},
    loading: true,
    loadingService: true,
    service:{},
  },
  getters: {
    loading: (state) => state.loading,
    travelChart: (state) => state.travelChartData,
    cycleChart: (state) => state.cycleChartData,
    mixedChart: (state) => state.mixedChartData,
    production: (state) => state.item.productionCard,
    cycle: (state) => state.item.cycleCard,
    service: (state) => state.service,
    loadingService: (state) => state.loadingService,
  },
  mutations: {
    [types.SET](state, payload) {
      state.item = payload;
    },
    [types.SET_TRAVEL_CHART](state, payload) {
      state.travelChartData = changeTravelChart(payload, state.item);
    },
    [types.SET_MIXED_CHART](state, payload) {
      state.mixedChartData = changeMixedChart(payload, state.item);
    },
    [types.SET_SERVICE](state, payload) {
      state.service = payload;
    },
    [types.SET_CYCLE_CHART](state, payload) {
      state.cycleChartData = changeCycleChart(payload, state.item);
    },
    [types.SET_LOADING](state, payload) {
      state.loading = payload;
    },
    [types.SET_LOADING_SERVICE](state, payload) {
      state.loadingService = payload;
    },
  },
  actions: {
    fetchItems({ commit }, payload) {
      commit(types.SET_LOADING, true);
      return axios
        .get(endPoint, { params: {filters:payload} })
        .then(({ data }) => {
          commit(types.SET, data.data);
          commit(types.SET_TRAVEL_CHART, defaultChartType);
          commit(types.SET_CYCLE_CHART, defaultChartType);
          commit(types.SET_MIXED_CHART, defaultMixedType);
          commit(types.SET_LOADING, false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getServices({ commit }, payload) {
      commit(types.SET_LOADING_SERVICE, true);
      return axios
        .get(`${endPoint}service`, { params: {filters:payload} })
        .then(({ data }) => {
          commit(types.SET_SERVICE, data.data);
          commit(types.SET_LOADING_SERVICE, false);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    changeMainChartData({ commit }, payload) {
      commit(types.SET_TRAVEL_CHART, payload);
      commit(types.SET_CYCLE_CHART, payload);
    },
    changeMixedChartData({ commit }, payload) {
      commit(types.SET_MIXED_CHART, payload);
    },
  },
};
