import "./axios";

const round = (number, decimalPlaces) => {
  const factorOfTen = Math.pow(10, decimalPlaces);
  return Math.round(number * factorOfTen) / factorOfTen;
};

export function toFixed(num, precission = null) {
  if (! num) {
    num = 0
  }
  if (!precission) {
    return num;
  }
  var decimals = Math.pow(10, precission);
  return Math.floor(num * decimals) / decimals;
}

export function formatDate(date) {
  let initials = date.slice(3, 6);
  if (
    (!isNaN(parseFloat(initials.charAt(0))) && isFinite(initials.charAt(0))) === false &&
    (!isNaN(parseFloat(initials.charAt(1))) && isFinite(initials.charAt(1))) === false &&
    (!isNaN(parseFloat(initials.charAt(2))) && isFinite(initials.charAt(2))) === false
  ) {
    let days = date.slice(0, 2);
    let year = date.slice(7, 11);
    let hour = date.slice(-5) + ":00";
    let month = "";
    switch (initials) {
      case "jan":
        month = "01";
        break;
      case "fev":
        month = "02";
        break;
      case "mar":
        month = "03";
        break;
      case "abr":
        month = "04";
        break;
      case "mai":
        month = "05";
        break;
      case "jun":
        month = "06";
        break;
      case "jul":
        month = "07";
        break;
      case "ago":
        month = "08";
        break;
      case "set":
        month = "09";
        break;
      case "out":
        month = "10";
        break;
      case "nov":
        month = "11";
        break;
      case "dez":
        month = "12";
        break;
    }
    return year + "-" + month + "-" + days + " " + hour;
  }
  return date;
}

// TODO : verificar os dois metodos de round
export const round1 = (num, dec) => {
  const [sv, ev] = num.toString().split("e");
  return Number(
    Number(Math.round(parseFloat(sv + "e" + dec)) + "e-" + dec) +
    "e" +
    (ev || 0)
  );
};

/**
 * Exemplo de uso:
 *     função: debounce(function (report) {
 *     }, 500),
 * @param func
 * @param delay
 * @returns {(function(): void)|*}
 */
export const debounce = function (func, delay) {
  //   metodo para adicionar um timer antes de executar a funcao
  // caso a funcao seja chamada, por exemplo: usuario digitando... reseta o timer e nao executa a funcao ate o timer finalizar
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer); // limpa o timer caso esteja definido
    timer = setTimeout(function () {
      func.apply(context, args); // executa a funcao conforme o timer
    }, delay); // adiciona um timeout para executar a funcao
  };
};

export const formatErrorValidation = errors => {
  let format_errors = Object.values(errors);
  return format_errors
    .flat()
    .toString()
    .split(",")
    .join("<br />");
};

export const mask1 = function (str, decimal = 0, digit = ".", nullable = true) {
  const indexOfAll = (arr, val) =>
    arr.split("").reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);

  // if (decimal instanceof Array){
  //   return maskArr(str, decimal)
  // }
  str = str.toString();

  str = str.replace(/[a-zA-Z ]/g, "");

  if (str.includes("-") && str.indexOf("-") != 0) {
    return mask1(str.replaceAll("-", ""), decimal);
  }
  if (indexOfAll(str, "-").length > 1) {
    var teste = str.charAt(0) === "-" ? "-" : "";
    return mask1(teste + str.replaceAll("-", ""), decimal);
  }
  if (str.substring(0, 2) === "0." && str.length > 2) {
    str = str.substring(2);
  }

  if (str.charAt(0) == 0 && str.length > 1 && str != "0.") {
    str = str.substring(1);
  }

  if (
    str.charAt(0) == "-" &&
    str.charAt(1) == "0" &&
    str.length > 2 &&
    str != "-0."
  ) {
    str = "-" + str.substring(2);
  }

  // var fz = "-0."
  // var fy = "0."
  // // comecando a regra de adicionar mais de 2 decimais
  // let contains = [];
  // for(var i = 0; i < decimal;i++){
  //     fz = fz + "0"
  //     fy = fy + "0"
  //     contains.push(fz)
  //     contains.push(fy)
  //   }

  //   for (let k of contains){
  //     if (str.replace('.', '') == k.replace('.','')){
  //       // return k
  //     }
  //   }

  if (!str || str.length == 0) {
    return nullable ? null : "0";
  }
  if (decimal == 0) {
    return str;
  }

  if (str.length == 1 && str.charAt(0) == "-") {
    return str;
  }

  if (str.replace(".", "") === "-0") {
    return str.replace(".", "");
  }

  if (str.replace(".", "") == "0") {
    return str;
  }

  str = str.toString();
  var decimals = "";
  let zero = decimal - str.length;
  for (var i = 0; i < zero; i++) {
    decimals = decimals.concat("0");
  }
  // removemos o sinal de negativo para não haver conflito
  str = str.replaceAll(".", "").replaceAll(",", "");
  if (str.charAt(0) == "-" && str.charAt(1) == "0" && str.length == 2) {
    return "-0" + digit + decimals + "0";
  }
  // caso seja negativo
  if (str < 0) {
    // removemos o sinal de negativo para não haver conflito
    str = str.replaceAll("-", "");
    // removemos o zero adicional alocado
    if (str.charAt(0) == "0") {
      str = str.slice(1);
    }
    // str = str.length <= decimal && "0" + digit + decimals + str || str.slice(0, - decimal) + digit + str.substr(str.length - decimal)
    str = mask1(str, decimal);
    return "-" + str;
  }
  if (
    str.replace(".", "") < 1 &&
    str.replace(".", "") > -1 &&
    str.charAt(0) == "-"
  ) {
    // var s = "-0."
    // for(var i = 0; i < decimal;i++){
    // s = s + "0"
    // }
    // return s
    return "-0";
  }
  if (
    str.replace(".", "") < 1 &&
    str.replace(".", "") > -1 &&
    str.charAt(0) == "0"
  ) {
    // var s = "-0."
    // for(var i = 0; i < decimal;i++){
    // s = s + "0"
    // }
    // return s
    return "0";
  }
  str =
    (str.length <= decimal && "0" + digit + decimals + str) ||
    str.slice(0, -decimal) + digit + str.substr(str.length - decimal);

  return str;
};
export const base_url_ms = () => {
  let baseURLMS = process.env.VUE_APP_BASE_URI_MS
  let baseURL = process.env.VUE_APP_BASE_URI;
  if (!baseURL) {
    let host = window.location.host
    let subdomain = host.split('.')[0]
    baseURL = `https://${subdomain}.puzl.place/api/v1/`;
  }
  if (!baseURLMS && process.env.NODE_ENV === 'development') {
    return 'https://testing.puzl.place/api/v1/'
  } else if (baseURLMS && process.env.NODE_ENV === 'development') {
    return baseURLMS
  } else {
    return baseURL.replace('api', 'microservice')
  }
}
export const maskArr = function (str, masks) {
  //   funcao para adicionar mascara a uma string, exemplo : mask(1010,['##.##']) => retorna 10.10
  let newMasks = {};
  let max = [];

  str = str.toString();
  for (let obj of masks) {
    // cria um array com o lengh da mascara
    max.push(obj.replace(".", "").replace(",", "").length);
    // remove os caracteres especiais e adiciona a mascara com a lenght como indice
    newMasks[obj.replace(".", "").replace(",", "").length] = obj;
  }
  //   corta a string conforme o length da mascara com mais caracteres
  str = str.replaceAll(".", "");
  str = str.substr(0, Math.max.apply(Math, max));
  //   quebra a string em um array
  let str1 = str.split("");

  // pega a mascara para o length informado, exemplo: string == 123 retorna a mascara informada #.##
  let mask2 = newMasks[str.length];

  //   quebra a mascara em um array
  masks = mask2.split("");

  //   inicia o valor para definir a mascara
  let newMask = "";
  //   percorre a string
  for (let index in str1) {
    //     busca a string na mascara
    if (index in masks) {
      //      valida se eh um caractere especial
      if (mask2[index] == "#") {
        newMask = newMask.concat(str1[index]);
      } else {
        //        se for um caractere especial, adiciona o caractere e em seguida a string
        newMask = newMask.concat(mask2[index], str1[index]);
      }
    }
  }
  //   retorna a string com o tamanho maximo da mask
  //   TODO : VERIFICAR NECESSIDADE DO substr()
  return newMask;
};
