import axios from 'axios'
import * as types from './mutation_types'
const endPoint = '/company/plant/'
export default {
  namespaced: true,
  state: {
    item:{},
  },
  getters:{
    show: state => state.item,
  },
  mutations: {
    [types.SHOW] (state, payload){
      state.item = payload
    },
  },
  actions: {
    getByCompanyPlant({}, payload) {
      return axios.get(`${endPoint}${payload}/plant-buyer`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({}, payload) {
      return axios.post(`${endPoint}plant-buyer`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({}, payload) {
      return axios.put(`${endPoint}plant-buyer/${payload.id}`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit}, payload) {
      return axios.get(`${endPoint}plant-buyer/${payload}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({}, payload) {
      return axios.delete(`${endPoint}plant-buyer/${payload}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    setDefaultCompanyPlantBuyer({}, payload) {
      return axios.get(`${endPoint}${payload.company_plant_id}/plant-buyer/${payload.buyer_id}/default`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
