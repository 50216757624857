import * as types from '../mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/configuration/fees-and-taxes/deduction-rules/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    activeItems (state) {
      return state.items.filter(item => item.status === true)
    },
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload, 'item')
    },
  },
  actions: {
    fetchItem({commit}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItems({commit}) {
      return axios.get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemByRegime({commit, state}, payload) {
      return axios.get(`${endPoint}getByRegime/${payload.regime}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return axios.put(endPoint+payload.uuid, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(endPoint+uuid)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getAllCurrentTaxes({ commit, state }, payload) {
      return axios.get(`${endPoint}current/${payload.constructionCity}/${payload.issuerRegime}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getCurrentTaxes({ commit, state }, params) {
      return axios.get(`${endPoint}current-by-commercial-tax/${params.issuerRegime}/${params.commercialTax}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
