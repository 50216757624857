import * as types from "./mutation_types";
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from "axios";

const endPoint = "/financial/bill-receive/";
var nextPage = null
export default {
  namespaced: true,
  state: {
    items: [],
    paginate: [],
    entities: [],
    item: [],
    history: []
  },
  getters: {
    show: state => state.item,
    fetch: state => state.items,
    paginate: state => state.paginate,
    getEntities: state => state.entities,
    getHistory: state => state.history,
    fetchNextPage: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_ENTITIES](state, payload) {
      state.entities = payload;
    },
    [types.SET_HISTORY](state, payload) {
      state.history = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload[0]);
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.SET_NEXT_PAGE](state, payload) {
      nextPage = payload.next_page
    },
    [types.SET_PAGINATE](state, payload) {
      state.paginate = payload;
    },
    [types.SET_NEXT_PAGE_DATA](state, payload) {
      state.items = state.items.concat(payload)
    },
  },
  actions: {
    fetch({commit, state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'items', 'installment_id')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getAll({commit, state}, params) {
      return axios.patch(`${endPoint}`, params)
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'items', 'installment_id')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    showEqualList({commit, state}, id) {
      return axios.get(`${endPoint}equal/${id}`)
        .then(({data}) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getEntities({commit, state}, params) {
      return axios.get(setUrlWithNextPage(endPoint + 'entities', params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'entities', 'installment_id')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchNextPage({commit}, payload) {
      return axios.get(nextPage, {params: payload})
        .then(({data}) => {
          commit(types.SET_NEXT_PAGE_DATA, data.data)
          commit(types.SET_NEXT_PAGE, {next_page: data.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getDetails({commit}, payload) {
      return axios
        .get(endPoint + 'details/' + payload.id + '/type/' + payload.type
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getAttachments({commit}, params) {
      return axios
        .get(endPoint + 'attachments/' + params.id + '/type/' + params.type
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getHistory({commit}, payload) {
      return axios
        .get(endPoint + 'history/' + payload.installment_id + '/type/' + payload.type
        )
        .then(({data}) => {
          commit(types.SET_HISTORY, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getInvoices({commit}, payload) {
      return axios
        .get(endPoint + 'invoices/' + payload.id
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    add({commit, state}, payload) {
      return axios
        .post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postIdentify({commit, state}, params) {
      return axios
        .post(`${endPoint}identify`, params)
        .then(({data}) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postChangeDueDate({commit, state}, params) {
      return axios
        .post(`${endPoint}change-due-date`, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    addInvoicesToPayment({commit, state}, payload) {
      return axios
        .post(endPoint + 'payment/invoices', payload)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    update({commit}, payload) {
      return axios
        .post(endPoint + payload.get('id'), payload)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    show({commit, dispatch}, uuid) {
      return axios
        .get(endPoint + uuid)
        .then(({data}) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    showLight({commit, dispatch}, uuid) {
      return axios
        .get(endPoint + 'light/' + uuid)
        .then(({data}) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getInfoBillet({commit, dispatch}, params) {
      return axios
        .get(endPoint + 'info-billet/' + params.id, {params: params})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    generateIndividualBillet({commit, dispatch}, params) {
      return axios
        .post(endPoint + 'bank-billet-individual/' + params.id, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    generateBillet({commit, dispatch}, params) {
      return axios
        .post(endPoint + 'bank-billet/' + params.id, params)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    generateQrcodePix({commit, dispatch}, params) {
      return axios
        .post(endPoint + 'qrcode-pix', params)
        .then(({data}) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    downloadPdfPix({commit, dispatch}, params) {
      return axios
        .get(endPoint + 'qrcode-pix-pdf', {
          responseType: 'blob'
        })
        .then(({data}) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    changeInstallmentStatus({commit, dispatch}, payload) {
      return axios
        .get(endPoint + 'update-installment/' + payload.id + '/type/' + payload.type + '/status/' + payload.status, {params: payload})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    postSendToEmail({commit, dispatch}, params) {
      return axios
        .post(endPoint + 'allotments/mail', {ids: params.send_emails, copy: params.copy})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    destroy({commit, dispatch}, uuid) {
      return axios
        .delete(endPoint + uuid)
        .then(({data}) => {
          commit(types.DESTROY, uuid);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    destroyEntity({commit, dispatch}, id) {
      return axios
        .delete(endPoint + 'entity/' + id)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getBillReceiveExcelReport({commit}, params) {
      return axios.get(endPoint + 'download-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    /**
     * Relatório de contas a receber
     * @param commit
     * @param params
     * @returns {Promise<unknown>}
     */
    getReport({commit}, params) {
      return axios.get(endPoint + 'report', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getOverdueInstallmentsByEntity({commit}, entity_id) {
      return axios
        .get(`${endPoint}overdue-installments/entity/${entity_id}`)
        .then(({data}) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    /**
     * Verificar integração de cartões por período
     * @param {string} payload.startAt yyyy-mm-dd
     * @param {string} payload.endAt yyyy-mm-dd
     */
    checkCardIntegration({}, payload) {
      return axios
        .post(`${endPoint}check-card-integration/${payload.startAt}/${payload.endAt}`)
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getEntityBillReceiveReportExcel({_}, params) {
      return axios.get(endPoint + 'entity-bill-receive/report-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
};
