import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/commercial/contract-proposal/payment/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}) {
      return axios.get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getByContractProposal({commit}, payload) {
      return axios.get(endPoint+'contract/'+payload.uuid, {params: payload})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return axios.post(endPoint + payload.get('uuid'), payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    postGenerateNewSharedPayment({commit}, params) {
      return axios.post(endPoint + 'shared/payment', params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getInvoices({commit}, id) {
      return axios
        .get(endPoint + 'invoices/' + id
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getInstallments({commit}, id) {
      return axios
        .get(endPoint + 'installments/' + id
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getAttachments({commit}, uuid) {
      return axios
        .get(endPoint + 'attachments/' + uuid
        )
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    changeInstallmentStatus({commit, dispatch}, payload) {
      return axios.get(endPoint+'installment/'+payload.uuid+'/status/'+payload.status)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(endPoint+uuid)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    postPayOffPayment({}, id) {
      return axios.post(`${endPoint}pay-off/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getCreditPaymentData({}, payload) {
      return axios.get(`${endPoint}credit-payment-data/${payload}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
