import * as types from './mutation_types'
import axios from 'axios'
import {destroy, update} from "@/store/baseStore";
const endPoint = '/mix/mixe+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    without_references: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    fetchWithoutReferences: state => state.without_references,
    activeItems (state) {
      return state.items.filter(item => item.status === 1)
    },
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_WITHOUT_REFERENCE] (state, payload){
      state.without_references = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}) {
      return axios.get(endPoint)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchGroupsWithoutReference({commit}, uuid) {
      return axios.get(endPoint+'/groups/without-reference/'+uuid)
        .then(({data}) =>{
          commit(types.SET_WITHOUT_REFERENCE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(endPoint+`/${uuid}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchMixesByMixDesignUuid({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/mixdesigns/${uuid}`)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return axios.put(`${endPoint}/${payload.uuid}`, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    saveAndActivate({commit}, payload) {
      return axios.put(`${endPoint}/${payload.uuid}/save-and-activate`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    replicate({commit}, uuid) {
      return axios.post(`${endPoint}/${uuid}/replicate`)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    postSetObservation({commit}, params) {
      return axios.post(`${endPoint}/${params.id}/observation`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    postSetRequireTechnicalSchedule({commit}, params) {
      return axios.post(`${endPoint}/${params.id}/technical-schedule`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  },
}
