import * as types from "./mutation_types";
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from "axios";
const endPoint = "/financial/approval/";
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
  },
  actions: {
    fetch({commit, state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'items', 'id')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
};
