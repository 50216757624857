import * as types from './mutation_types'
import {destroy, update, cursorPaginate,setUrlWithNextPage, formatResponseData} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/financial/finance-transactions/'
const reconcileEndpoint = '/financial/finance-transactions/reconcile/many/'
const closeEndpoint = '/financial/finance-transactions/reconcile/close/'
export default {
  namespaced: true,
  state: {
    items: [],
    history: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    getInitialBalance({}, params){
      return axios.get(endPoint+ "bank-account-id/" + params.bank_account_id + "/date/" + params.date + "/opening-balance")
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItems({state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
      {params: params})
        .then(({data}) => {
          const formattedData = formatResponseData(data);
          cursorPaginate(state, {data : formattedData.data, next_page: params.page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit}, params) {
      return axios.post(endPoint, params)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, params) {
      return axios.put(endPoint+params.id, params)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    history({state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
      {params: params})
        .then(({data}) => {
          const formattedData = formatResponseData(data);
          cursorPaginate(state, {data : formattedData.data, next_page: params.page}, 'history')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit}, uuid) {
      return axios.delete(endPoint+uuid)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    reconcile({}, params) {
      return axios.post(reconcileEndpoint, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    close({}, params) {
      return axios.post(closeEndpoint, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    generatePdfReport({}, params) {
      return axios.post(`${endPoint}generate/1/report`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
