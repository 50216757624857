import * as types from "./mutation_types";
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from "axios";
const endPoint = "financial/bill-receive-anticipation-balance";
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
  },
  actions: {
    fetchItemsPaginate({commit, state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getCountTotal({commit}) {
      return axios.get(`${endPoint}/totals`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
};
