import axios from 'axios'

const endPoint = '/commercial'
export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  actions: {
  // document
    createDocument({commit, state}, payload) {
      return axios.post( `${endPoint}/zap-sign/document/create`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchDocuments({commit, state}, params) {
      return axios.get(`${endPoint}/zap-sign/documents`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    deleteDocument({commit, dispatch}, documentId) {
      return axios.delete(`${endPoint}/zap-sign/document/${documentId}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  // signer
    addSigner({commit, state}, payload) {
      return axios.post( `${endPoint}/zap-sign/signer/add`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    removeSigner({commit, dispatch}, signerId) {
      return axios.delete(`${endPoint}/zap-sign/signer/${signerId}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}

