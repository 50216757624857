import * as types from './mutation_types'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/administrative/delivery-resume'

export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters:{
    fetch: state => state.items,
  },
  mutations: {
    [types.SET_CONCRETES](state, payload) {
      state.items = payload
    },
  },
  actions: {
    fetchConcretes({commit}, params) {
      return axios.get(`${endPoint}/concretes`, {params: params})
        .then(({data}) => {
          commit(types.SET_CONCRETES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchConcretesItemsPaginate({state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}/concretes`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getSReportExcel(_, params) {
      return axios.get(endPoint + '/download-excel', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
