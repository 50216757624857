import * as types from "./mutation_types";
import * as charts from "./charts";
import axios from "axios";

const endPoint = "/technology/dashboard+microservice";
export default {
  namespaced: true,
  state: {
    average: {},
    show: {},
    theorical_statistical: {},
    real_statistical: {},
    standard_deviation: {},
    pieChart: {},
    variationGaugeChart: {},
    coefficientGaugeChart: {},
    responsibilityBarChart: {},
    osBarChart: {},
  },
  getters: {
    show: (state) => state.show,
    average: (state) => state.average,
    getTheoricalStatistical: (state) => state.theorical_statistical,
    getRealStatistical: (state) => state.real_statistical,
    getStandardDeviation: (state) => state.standard_deviation,
    pieChart: (state) => state.pieChart,
    variationGaugeChart: (state) => state.variationGaugeChart,
    coefficientGaugeChart: (state) => state.coefficientGaugeChart,
    responsibilityBarChart: (state) => state.responsibilityBarChart,
    osBarChart: (state) => state.osBarChart,
  },
  mutations: {
    [types.SET](state, payload) {
      state.show = payload;
    },
    [types.SET_AVERAGE](state, payload) {
      state.average = payload;
    },
    [types.SET_THEORICAL_STATISTICAL](state, payload) {
      state.theorical_statistical = payload;
    },
    [types.SET_REAL_STATISTICAL](state, payload) {
      state.real_statistical = payload;
    },
    [types.SET_STANDARD_DEVIATION](state, payload) {
      state.standard_deviation = payload;
    },
    [types.SET_PIE_CHART](state, payload) {
      state.pieChart = charts.handlePieChart(payload);
    },
    [types.SET_VARIATION_GAUGE_CHART](state, payload) {
      state.variationGaugeChart = charts.handleVariationGaugeChart(payload);
    },
    [types.SET_COEFFICIENT_GAUGE_CHART](state, payload) {
      state.coefficientGaugeChart = charts.handleCoefficientGaugeChart(payload);
    },
    [types.SET_RESPONSIBILITY_BAR_CHART](state, payload) {
      state.responsibilityBarChart = charts.handleResponsibilityBarChart(payload);
    },
    [types.SET_OS_BAR_CHART](state, payload) {
      state.osBarChart = charts.handleOsBarChart(payload);
    },
  },
  actions: {
    fetch({commit}, params) {
      return axios
        .get(endPoint, {params: params})
        .then(({data}) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchAverage({commit}, params) {
      return axios
        .get(endPoint + "/average", {params: params})
        .then(({data}) => {
          commit(types.SET_AVERAGE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchFeatureChart({commit, state}, params) {
      return axios
        .get(endPoint + "/feature-chart", {params: params})
        .then(({data}) => {
          commit(types.SET_RESPONSIBILITY_BAR_CHART, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getBarChart({commit, state}, params) {
      return axios
        .get(endPoint + "/os-bar-chart", {params: params})
        .then(({data}) => {
          commit(types.SET_OS_BAR_CHART, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    postStorStatisticalReference({commit}, params) {
      return axios.post(endPoint + '/statistical-reference', params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getStatisticalReference({commit, state}, params) {
      return axios
        .get(endPoint + "/statistical-reference", {params: params})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getTheoricalStatistical({commit, state}, params) {
      return axios
        .get(endPoint + "/theorical-statistical", {params: params})
        .then(({data}) => {
          commit(types.SET_THEORICAL_STATISTICAL, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getStandardDeviation({commit, state}, params) {
      return axios
        .get(endPoint + "/standard-deviation", {params: params})
        .then(({data}) => {
          commit(types.SET_VARIATION_GAUGE_CHART, data.data);
          commit(types.SET_COEFFICIENT_GAUGE_CHART, {
            max: data.data.cv_max,
            value: ((data.data.value / data.data.f_in_line ) * 100).toFixed(1)
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getRealStatistical({commit, state}, params) {
      return axios
        .get(endPoint + "/real-statistical", {params: params})
        .then(({data}) => {
          commit(types.SET_REAL_STATISTICAL, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getPieChart({commit}, payload) {
      commit(types.SET_PIE_CHART, payload);
    },
    getVariationGaugeChart({commit}, payload) {
      commit(types.SET_VARIATION_GAUGE_CHART, payload);
    },
    getCoefficientGaugeChart({commit}, payload) {
      commit(types.SET_COEFFICIENT_GAUGE_CHART, payload);
    },
    getResponsibilityBarChart({commit}, payload) {
      commit(types.SET_RESPONSIBILITY_BAR_CHART, payload);
    },
    getOsBarChart({commit}, payload) {
      commit(types.SET_OS_BAR_CHART, payload);
    },
  },
};
