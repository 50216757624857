import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'

const endPoint = '/technology/equipments'
export default {
  namespaced: true,
  state: {
    items: [],
    equipment_types: [],
  },
  getters: {
    fetch: state => state.items,
    getEquipmentTypes: state => state.equipment_types,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_TYPES](state, payload) {
      state.equipment_types = payload
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return axios.get(endPoint)
        .then(({data}) => {
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add({commit}, params) {
      return axios.post(endPoint, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    fetchEquipmentTypeItems({commit}, params) {
      return axios.get(endPoint + '/types',)
        .then(({data}) => {
          commit(types.SET_TYPES, data.data)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
