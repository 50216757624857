import * as types from "./mutation_types";
import axios from "axios";
const endPoint = "/configuration/entity/anticipation-balance-transfer/";
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
  },
  actions: {
    fetch({ commit }, payload) {
      return axios
        .get(`${endPoint}${payload.id}`, { params: payload })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({}, payload) {
      return axios
        .post(`${endPoint}${payload.from_entity_id}`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
