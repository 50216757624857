import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/configuration/user/'
export default {
  namespaced: true,
  state: {
    items: [],
    usersForModal: [],
    filterable: [],
    item: [],
    itemCountUsers: [],
    drivers: [],
    sellers:[],
    entry_drivers: [],
    notifications: [],
    activeSellersSimplified: [],
    activeUsersSimplified: [],
    activeSellersAllocatedByUserId: [],
    activeUsersIntermediaries: [],
  },
  getters:{
    show: state => state.item,
    showCountUsers: state => state.itemCountUsers,
    filterable: state => state.filterable,
    fetch: state => state.items,
    fetchForModalList: state => state.usersForModal,
    getDrivers: state => state.drivers,
    sellers: state => state.sellers,
    getNotifications: state => state.notifications,
    getSellerWithAll: state => {
      return [{id: 'all', name: 'Todos'}, ...state.filterable]
    },
    getAllActiveSellersSimplified: state => state.activeSellersSimplified,
    getAllActiveUsersSimplified: state => state.activeUsersSimplified,
    getAllActiveSellersAllocatedByUserId: state => state.activeSellersAllocatedByUserId,
    getEntryDrivers: state => state.entry_drivers,
    getActiveUsersIntermediaries: state => state.activeUsersIntermediaries,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.SET_MODAL_LIST] (state, payload){
      state.usersForModal = payload
    },
    [types.SET_FILTERABLE] (state, payload){
      state.filterable = payload
    },
    [types.SET_DRIVER] (state, payload){
      state.drivers = payload
    },
    [types.SET_SELLER] (state, payload){
      state.sellers = payload
    },
    [types.SET_NOTIFICATIONS] (state, payload){
      state.notifications = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.SHOW_COUNT_USERS] (state, payload){
      state.itemCountUsers = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
    [types.SET_ACTIVE_SELLERS_SIMPLIFIED] (state, payload){
      state.activeSellersSimplified = payload
    },
    [types.SET_ACTIVE_USERS_SIMPLIFIED] (state, payload){
      state.activeUsersSimplified = payload
    },
    [types.SET_ACTIVE_SELLERS_ALLOCATED_BY_USER_ID] (state, payload){
      state.activeSellersAllocatedByUserId = payload
    },
    [types.SET_ENTRY_DRIVERS] (state, payload){
      state.entry_drivers = payload
    },
    [types.SET_ALL_INTERMEDIARIES] (state, payload){
      state.activeUsersIntermediaries = payload
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsForModalList({commit}, params) {
      return axios.get(endPoint + 'active-sellers', {params: params})
        .then(({data}) =>{
          commit(types.SET_MODAL_LIST, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsFilterable({commit}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET_FILTERABLE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getByCompanyPlant({commit},payload) {
      return axios.get(endPoint+'company-plant/'+( typeof payload === 'string' ? payload : payload.company_plant_uuid), {params : payload})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchDrivers({commit}, params) {
      return axios.get(`configuration/user-drivers`, {params: params})
        .then(({data}) =>{
          commit(types.SET_DRIVER, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return axios.put(`${endPoint}${payload.uuid}`,  payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(endPoint+uuid)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(endPoint+uuid)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    setViewSellers({commit, dispatch}, params) {
      return axios.post(endPoint+'set-sellers', params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    setBankAccount({commit, dispatch}, params) {
      return axios.post(endPoint+'set-bank_accounts', params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchSellers({commit}) {
      return axios.get(endPoint+'sellers')
        .then(({data}) =>{
          commit(types.SET_SELLER, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getDocument({commit, dispatch}, id) {
      return axios.get(`${endPoint}document/${id}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getCountUsers({commit, dispatch}) {
      return axios.get(`${endPoint}total-users`)
        .then(({data}) =>{
          commit(types.SHOW_COUNT_USERS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getSellersAllocatedByPlant({commit}, plant_uuid) {
      return axios.get(`${endPoint}allocated-by-plant/${plant_uuid}`)
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getUserNotifications({commit}, user_id) {
      return axios.get(`${endPoint}notifications/${user_id}`)
        .then(({data}) =>{
          commit(types.SET_NOTIFICATIONS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateStatus({}, payload) {
      return axios.put(`${endPoint}update-status/${payload.id}/${payload.status}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    setNotify({}, params) {
      return axios.put(`${endPoint}notify/${params.user_id}`, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getAllActiveSellersSimplified({commit}) {
      return axios.get(endPoint + 'sellers-simplified/active')
        .then(({data}) =>{
          commit(types.SET_ACTIVE_SELLERS_SIMPLIFIED, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getAllActiveUsersSimplified({commit}) {
      return axios.get(endPoint + 'users-simplified/active')
        .then(({data}) =>{
          commit(types.SET_ACTIVE_USERS_SIMPLIFIED, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getAllActiveSellersAllocatedByUserId({commit}, id) {
      return axios.get(endPoint + 'allocated-by-id/' + id)
        .then(({data}) =>{
          commit(types.SET_ACTIVE_SELLERS_ALLOCATED_BY_USER_ID, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getAllWithFilter({commit}, params) {
      return axios.get(`${endPoint}with-filter`, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getSearch({commit}, params) {
      return axios.get(`${endPoint}search/filterable`, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getSellersSimplified({commit}) {
      return axios.get(endPoint+'sellers/simplified')
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getEntryDrivers({commit}) {
      return axios.get(`${endPoint}`+'driver/is-entry-driver')
        .then(({data}) =>{
          commit(types.SET_ENTRY_DRIVERS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    userGetAllIntermediary({commit}) {
      return axios.get(endPoint+'simplified/all-intermediary')
        .then(({data}) =>{
          commit(types.SET_ALL_INTERMEDIARIES, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
