import axios from 'axios'
import {cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'

const endPoint = 'commercial/digital-signature-resume'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  actions: {
    fetchItemsPaginate({commit, state}, params) {
      return axios.get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data: data.data, next_page: params.next_page })
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getWidgets({commit}, params) {
      return axios.get(`${endPoint}/status-count`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getOriginalDocumentFile({commit}, id) {
      return axios.get(`${endPoint}/original-document-file/${id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getSignedDocumentFile({commit}, id) {
      return axios.get(`${endPoint}/signed-document-file/${id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
