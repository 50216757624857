import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/financial/payment-intermediary-merchant'
export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
    sales: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    getSales: state => state.sales,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
    [types.SET_SALES](state, payload) {
      state.sales = payload;
    },
  },
  actions: {
    fetchItems({commit}, payload) {
      return axios.get(endPoint, { params: payload })
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    createOrUpdateMany({commit}, payload) {
      return axios.post(`${endPoint}/payment-intermediary-id/${payload.paymentIntermediaryId}`, payload.paymentIntermediaryMerchants)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return axios.put(`${endPoint}/${payload.id}`, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit}, id) {
      return axios.get(`${endPoint}/${id}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit}, id) {
      return axios.delete(`${endPoint}/${id}`)
        .then(({data}) =>{
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getTransaction({}, payload) {
      return axios.get(`${endPoint}/${payload.id}/transaction-code/${payload.transactionCode}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getSalesFromCardIntegration({commit}, payload){
      return axios.get(`${endPoint}/${payload.id}/${payload.startDate}/${payload.endDate}/sales`)
      .then(({data}) =>{
        commit(types.SET_SALES, data.data)
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })  
    },
    getSaleFromCardIntegrationByNsuCode({}, payload){
      return axios.get(`${endPoint}/${payload.id}/${payload.saleDate}/nsu-code/${payload.nsuCode}/sale`)
      .then(({data}) =>{
        return Promise.resolve(data)
      }).catch(error =>{
        return Promise.reject(error)
      })
    }
  }
}
