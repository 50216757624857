import * as types from "./mutation_types";
import axios from "axios";
import { destroy, update } from "@/store/baseStore";
import { cursorPaginate, setUrlWithNextPage, formatResponseData } from "@/store/baseStore";
const endPoint = "/configuration/tasks/";

export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    history: [],
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
    getHistory: (state) => state.history,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.SET_HISTORY](state, payload) {
      state.history = payload;
    },
  },
  actions: {
    fetchItems({ state }, params) {
      return axios
        .get(setUrlWithNextPage(endPoint, params), { params: params })
        .then(({ data }) => {
          const formattedData = formatResponseData(data);
          cursorPaginate(state, {
            data: formattedData.data,
            next_page: params.page,
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, params) {
      let formData = new FormData();

      formData.append("description", params.description);
      formData.append("priority", params.priority);
      formData.append("limit_date", params.limit_date);
      formData.append("receiver_user_id", params.receiver_user_id);
      formData.append(
        "observation",
        params.observation != null ? params.observation : ""
      );
      formData.append("status", params.status);

      if (params && params.form_data.length) {
        params.form_data.forEach((el) => {
          formData.append(`files[${el.index}]`, el.file);
        });
      }

      return axios
        .post(endPoint, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit }, id) {
      return axios
        .get(endPoint + id)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getHistory({ commit }, id) {
      return axios
        .get(endPoint + "history/" + id)
        .then(({ data }) => {
          commit(types.SET_HISTORY, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, params) {
      return axios
        .put(`${endPoint}${params.id}`, params)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateStatus({ commit }, params) {
      return axios
        .put(`${endPoint}status/${params.id}/${params.status}`, params)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateAware({ commit }, params) {
      return axios
        .put(`${endPoint}aware/${params.id}`, params)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, id) {
      return axios
        .delete(endPoint + id)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
