import * as types from './mutation_types'
import axios from 'axios'
const endPoint = '/nfe'
export default {
  namespaced: true,
  state: {
    taxes: [],
    exit_cfops: [],
  },
  getters:{
    getListTaxes: state => state.taxes,
    getListExitCfops: state => state.exit_cfops,
  },
  mutations: {
    [types.SET_TAXES] (state, payload){
      state.taxes = payload
    },
    [types.SET_EXIT_CFOPS] (state, payload){
      state.exit_cfops = payload
    },
  },
  actions: {
    getTaxes({commit}, params) {
      return axios.get(`${endPoint}/taxes`, {params: params})
        .then(({data}) =>{
          commit(types.SET_TAXES, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getExitCfops({commit}, params) {
      return axios.get(`${endPoint}/exit-cfop`, {params: params})
        .then(({data}) =>{
          commit(types.SET_EXIT_CFOPS, Object.values(data.data))
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    postStoreNfe({commit}, exit_launch_id) {
      return axios.post(`${endPoint}/${exit_launch_id}+microservice`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
