<template>
  <modal :show.sync="modal.create">
    <template slot="header">
      <h5 class="modal-title">{{ modal.title }}</h5>
    </template>
    <div>
      <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
        <form class="needs-validation p-2 mt-2" @submit.prevent="handleSubmit(store)" autocomplete="off">
          <!-- Nome -->
          <div class="form-group row m-0 p-0 mb-1">
            <label class="col-md-6 pl-2 pt-2 pb-0 col-form-label form-control-label">
              Nome
              <span class="text-danger">&nbsp;*</span>
            </label>
            <div class="col-md-6">
              <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm">
                  <input v-model="payload.name" type="text" class="form-control form-control-sm" maxlength="80" />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="modal-footer">
            <base-button type="secondary" @click="closeModal('create')"> Cancelar </base-button>
            <base-button type="success" native-type="submit" v-bind:disabled="invalid" :loading="loadingStore"> Salvar </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import { formatErrorValidation } from "@/plugins";

export default {
  name: "ModalCreate",
  data() {
    return {
      modal: {
        title: "Local de Estoque",
        create: false,
      },
      payload: {
        name: null,
      },
      loadingStore: false,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    openModal() {
      this.payload = {
        name: null,
      };
      this.modal.create = true;
    },
    store() {
      this.loadingStore = true;
      this.$store
        .dispatch("stockLocation/add", this.payload)
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
        });
    },
  },
};
</script>
