import axios from 'axios'
const endPoint = '/configuration/user/permission-change'
export default {
  namespaced: true,
  actions: {
    store({_}, params) {
      return axios.post(endPoint, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
  }
}
