import * as types from "../mutation_types";
import axios from "axios";
const endPoint = "/configuration/entry-launch/";
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
  },
  actions: {
    getByEntryLaunch({ commit }, payload) {
      return axios
        .get(`${endPoint}${payload}/item`)
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({}, payload) {
      return axios
        .post(`${endPoint}item`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    checkRegister({}, payload) {
      return axios
        .post(`${endPoint}item/check-register`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    storeEquipmentApportionments({}, payload) {
      return axios
        .post(`${endPoint}item/${payload.id}/equipment-apportionment`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    changeChartAccount(_, payload) {
      return axios
        .post(`${endPoint}item/change-chart-account`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
  },
};
