<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
      <!-- Header Menus -->
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="handleCreateModal" block size="lg" type="success" class="text-uppercase">
            <i class="fas fa-plus"></i> novo
          </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <back-button :path="'/configuration/general'" />
        </div>
      </div>
    </base-header>
    <List />
    <ModalCreate ref="modalCreate" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import List from "./Shared/_List";
import ModalCreate from "./Shared/_ModalCreate";
import BackButton from "@/components/Utils/BackButtonV2";
export default {
  name: "Index",
  components: { PuzlBreadcrumb, List, ModalCreate, BackButton },
  methods: {
    handleCreateModal() {
      this.$refs.modalCreate.openModal();
    },
  },
};
</script>
