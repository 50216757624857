import * as types from './mutation_types'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
    billing_units: [
      { id: 0, label: 'R$ (Fixo)', min: 0, max: 9999},
      { id: 2, label: 'R$ /m3', min: 0, max: 999},
      { id: 3, label: 'R$ / Hora', min: 0, max: 999},
      { id: 4, label: '% (Sobre Preço Contratado)', min: 0, max: 200},
    ],
    params: [
      { id: 0, label: 'Antes de (horas)', min: 0, max: 24},
      { id: 1, label: 'Após (horas)', min: 0, max: 24},
      { id: 2, label: 'Volume mínimo por carga (m3)', min: 0, max: 9},
      { id: 3, label: 'Permanência máx. em obra (minutos)', min: 0, max: 199},
      { id: 4, label: 'Prazo antes da concretagem (horas)', min: 0, max: 99},
      { id: 5, label: 'Prazo pós concretagem (horas)', min: 0, max: 99},
      { id: 6, label: 'Sobra de concreto maior que (m³)',
        min: 0, max : '9.9', decimals: 1},
    ],
    dates: [
      { id: 0, label: 'Dia útil'},
      { id: 1, label: 'Sábado'},
      { id: 2, label: 'Domingo'},
      { id: 3, label: 'Feriado'},
    ],
  },
  getters:{
    show: state => state.item,
    additionals: state => state.items,
    billingUnits: state => state.billing_units,
    params: state => state.params,
    dates: state => state.dates,
  },
  mutations: {
    [types.SET_ADDITIONAL] (state, payload){
      state.items = payload
    },
    [types.PUSH_ADDITIONAL] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW_ADDITIONAL] (state, payload){
      state.item = payload
    },
  },
  actions: {
    fetchAdditionals({commit}, params = null) {
      return axios.get('/configuration/additional', {params: params})
        .then(({data}) =>{
          commit(types.SET_ADDITIONAL, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    addAdditional({commit, state}, payload) {
      return axios.post('/configuration/additional', payload)
        .then(({data}) =>{
          commit(types.PUSH_ADDITIONAL, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return axios.put('/configuration/additional/'+payload.uuid, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return axios.get('/configuration/additional/'+uuid)
        .then(({data}) =>{
          commit(types.SHOW_ADDITIONAL, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete('/configuration/additional/'+uuid)
        .then(({data}) =>{
          dispatch('fetchAdditionals')
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
