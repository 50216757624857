import * as types from './mutation_types'
import { cursorPaginate, setUrlWithNextPage } from '@/store/baseStore'
import axios from 'axios';
const endPoint = '/financial/credit-release/+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: state => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
  },
  actions: {
    fetchItems({ state }, payload) {
      return axios.get(setUrlWithNextPage(endPoint, payload), { params: payload })
        .then(({ data }) => {
          cursorPaginate(state, { data: data.data, next_page: payload.next_page })
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
