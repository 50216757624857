import * as types from "./mutation_types";
import { destroy, update, cursorPaginate, setUrlWithNextPage } from "@/store/baseStore";
import axios from "axios";
const endPoint = "/equipment/fuel-control/";
export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
    total_liter: 0,
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
    getTotalLiter: (state) => state.total_liter,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
    [types.SET_TOTAL_LITER](state, payload) {
      state.total_liter = payload;
    },
  },
  actions: {
    fetch({ state }, params) {
      return axios
        .get(setUrlWithNextPage(endPoint, params), { params: params })
        .then(({ data }) => {
          cursorPaginate(state, { data: data.data, next_page: params.next_page });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, params) {
      return axios
        .post(endPoint, params)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit }, id) {
      return axios
        .get(endPoint + id)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, params) {
      return axios
        .post(endPoint + params.get('id'), params)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, id) {
      return axios
        .delete(endPoint + id)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getFuelDataByDateAndEquipment({}, params) {
      return axios
        .get(`${endPoint}fuel-data/${params.date}/${params.equipment_id}/${params.id}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getTotalLiter({commit}, payload) {
      return axios
        .get(`${endPoint}total-liter/`, {params : payload})
        .then(({ data }) => {
          commit(types.SET_TOTAL_LITER, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
