<template>
  <SlideYUpTransition :duration="animationDuration">
    <!--    @mousedown.self="closeModal"-->
    <div style="z-index: 10000 !important;"
         @keypress.esc="closeModal"
         class="modal fade"
         :class="[{ 'show d-block': show }, { 'd-none': !show }, { 'modal-mini': type === 'mini' }]"
         v-show="show"
         tabindex="-1"
         role="dialog"
         :aria-hidden="!show"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :style="size === 'xxl' ? 'max-width: 90%' : null"
        :class="[{ 'modal-notice': type === 'notice', [`modal-${size}`]: size }, modalClasses]"
      >
        <div class="modal-content" style="border-radius: 18px;"
             v-bind="$attrs"
             :style="'border: ' + borderInfo"
             :class="[gradient ? `bg-gradient-${gradient}` : '', modalContentClasses]">
          <div
            class="modal-header pb-1"
            style="border-radius: 15px;border-bottom-left-radius: 0px;border-bottom-right-radius: 0px;"
            :class="[headerClasses]"
            :style="{ backgroundColor: backgroundColor, height: heightTitle + 'px' }"
            v-if="$slots.header"
          >
            <slot name="header"></slot>
            <slot name="close-button">
              <button type="button" :style="headerClasses ? 'margin-top: -25px' : ''"
                class="close"
                v-if="showClose" @click="closeModal" data-dismiss="modal"
                :class="headerClasses ? [headerClasses] : 'text-white'"
                aria-label="Close">
                <i style="font-size: 20px" class="fas fa-times"></i>
              </button>
            </slot>
          </div>

          <div v-if="rerender" class="modal-body mt-2 p-2" :class="bodyClasses">
            <slot></slot>
          </div>

          <div class="modal-footer" :class="footerClasses" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>
<script>
import {SlideYUpTransition} from "vue2-transitions";

export default {
  inheritAttrs: false,
  name: "modal",
  components: {
    SlideYUpTransition
  },
  data() {
    return {
      rerender: true,
    }
  },
  props: {
    show: Boolean,
    borderInfo: {
      type: String,
      default: '0'
    },
    showClose: {
      type: Boolean,
      default: true
    },
    /**
     * Não irá utilizar o rerender, em casos que utilizam nextTick no próprio componente
     */
    useRerender: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "",
      validator(value) {
        let acceptedValues = ["", "notice", "mini"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") '
    },
    modalClasses: {
      type: [Object, String],
      description: "Modal dialog css classes"
    },
    size: {
      type: String,
      description: "Modal size",
      validator(value) {
        let acceptedValues = ["xl", "md", "md-2", "sm", "lg", "xxl"];
        return acceptedValues.indexOf(value) !== -1;
      }
    },
    modalContentClasses: {
      type: [Object, String],
      description: "Modal dialog content css classes"
    },
    heightTitle: {
      type: [Number],
      default: 67,
      description: "height modal title"
    },
    gradient: {
      type: String,
      description: "Modal gradient type (danger, primary etc)"
    },
    headerClasses: {
      type: [Object, String],
      description: "Modal Header css classes"
    },
    bodyClasses: {
      type: [Object, String],
      description: "Modal Body css classes"
    },
    footerClasses: {
      type: [Object, String],
      description: "Modal Footer css classes"
    },
    closeDown: {
      type: Boolean,
      default: true
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: "Modal transition duration"
    },
    backgroundColor: {
      type: String,
      default: '#1a70b7'
    },
  },
  methods: {
    closeModal() {
      if (!this.closeDown) {
        return
      }
      if (this.$parent.$props && this.$parent.$props.hasOwnProperty("show") ||
        this.$parent.hasOwnProperty("close")) {
        this.$parent.close()
      } else {
        this.$emit("update:show", false);
      }
    }
  },
  watch: {
    show(val) {
      /**
       * @alert componentes que necessitam de dados pré-definidos como input-file, input-date-picker não usar reRender.
       */
      if (this.useRerender) {
        this.rerender = false
        this.$nextTick(function () {
          this.rerender = true
        })
        this.$forceUpdate()
      }
      let documentClasses = document.body.classList;
      if (val) {
        documentClasses.add("modal-open");
      } else {
        documentClasses.remove("modal-open");
      }
    }
  }
};
</script>
<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-scroll-y {
  overflow-y: scroll !important;
}

.modal-header > h5, h6 {
  color: white;
}

.modal-md-2 {
  max-width: 550px !important;
}
.modal-footer {
  margin-right: -12px;
}
</style>
