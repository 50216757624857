import * as types from "./mutation_types";
import { destroy, update, cursorPaginate, setUrlWithNextPage } from "@/store/baseStore";
import axios from "axios";
const endPoint = "/configuration/stock-location/";
export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
  },
  actions: {
    fetch({ state }, payload) {
      return axios
        .get(setUrlWithNextPage(endPoint, payload), { params: payload })
        .then(({ data }) => {
          cursorPaginate(state, { data: data.data, next_page: payload.next_page });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, payload) {
      return axios
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit }, id) {
      return axios
        .get(endPoint + id)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      return axios
        .put(endPoint + payload.id, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit }, id) {
      return axios
        .delete(endPoint + id)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
