import axios from 'axios'
const endPoint = '/commercial/contract-proposal/credit'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
  },
  actions: {
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    getByContractProposal({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/proposal/${uuid}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getHistories({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/history/proposal/${uuid}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getBalance({commit, dispatch}, id) {
      return axios.get(`/commercial/contract-proposal/balance/${id}+microservice`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    disableCredit({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/disable/${uuid}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
