import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/support/deploy+microservice'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, id){
      destroy(state,id)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetch({commit}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateItems({commit}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    start({commit}, params) {
      return axios.post(endPoint, params)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    unlink({commit}, params) {
      return axios.put(`${endPoint}/sandbox/unlink/${params.id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
