import * as types from './mutation_types'
import {destroy, update} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/commercial/contract/additionals'
export default {
  namespaced: true,
  state: {
    items: [],
    activeItems: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    activeItems (state) {
      return state.items.filter(item => item.status === true).sort((a,b) => a.name > b.name)
    },
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE] (state, payload){
      const index = state.items.findIndex((item) => item.additional_uuid === payload.additional_uuid)
      state.items.splice(index, 1, payload);
    },
  },
  actions: {
    fetchItems({commit}, uuid) {
      return axios.get(endPoint+'/'+uuid+'/list')
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(endPoint, payload)
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return axios.put(`${endPoint}/${payload.uuid}`, payload)
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.DESTROY, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    createNewValidity({commit, state}, payload) {
      return axios.post(endPoint+'/new-validity', payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    setStatus({commit, state}, payload) {
      return axios.put(endPoint+'/set-status/'+payload.uuid, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
