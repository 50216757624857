import axios from 'axios'
import * as types from "./mutation_types";

export default {
  namespaced: true,
  state: {
    mentions: [],
    default_mention: null,
  },
  getters: {
    getListMentions: state => state.mentions,
    getDefaultMention: state => state.default_mention,
  },
  mutations: {
    [types.SET_MENTIONS](state, payload) {
      state.mentions = payload
    },
    [types.DEFAULT_MENTION](state, payload) {
      state.default_mention = payload
    },
  },
  actions: {
    createNfse ({}, payload) {
      return axios.post(`nfses/ms-company/${payload.ms_company_id.trim()}+enotas`, payload.data)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroyByNfseExternalId ({}, payload) {
      return axios.delete(`nfses/ms-company/${payload.ms_company_id.trim()}/nfse-external-id/${payload.nfse_external_id.trim()}+enotas`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    createNfseAllotment ({}, payload) {
      return axios.post(`nfses/allotments/+enotas`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getTaxRegimesByIbgeCode({}, ibgeCode) {
      return axios.get(`additional/tax-regimes-by-ibge-code/${ibgeCode}+enotas`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateInvoiceNfse ({}, payload) {
      return axios.put(`financial/billing-nfse/${payload.id}`, payload.data)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    showInvoiceNfse ({}, id) {
      return axios.get(`financial/billing-nfse/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateInvoiceNfseColumnAllotments ({}, payload) {
      return axios.put(`financial/billing-nfse-column-allotments`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    danfseDownload ({}, link) {
      return axios.get(link, {responseType: 'blob', timeout: 30000})
        .then(({data}) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
    getNotIssuedList({commit}, params) {
      return axios.patch(`/financial/billing-nfse`, params)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getMentions({commit}) {
      return axios.get(`/financial/nfse-mentions`)
        .then(({data}) => {
          commit(types.SET_MENTIONS, Object.values(data.data.mentions))
          commit(types.DEFAULT_MENTION, data.data.default)
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getObservationByMention({commit}, params) {
      return axios.get(`/financial/observation-by-mention`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getObservationByMentionAndInvoiceIds({commit}, params) {
      return axios.get(`/financial/observation-by-mention-and-invoice-ids`, {params: params})
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    getNfseExcelReport({commit}, params) {
      return axios.get('/financial/billing-nfse-report', {
        responseType: "blob",
        timeout: 60000,
        params: params
      })
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    saveImportedAuthorizedNfseXmlPdf({}, payload) {
      let formData = new FormData();
      formData.append('invoice_id', payload.invoice_id);
      formData.append('xml_file', payload.xml_file);
      formData.append('pdf_file', payload.pdf_file);
      formData.append('nfse', JSON.stringify(payload.nfse));
      return axios.post('/financial/billing-nfse/save-imported-authorized-xml-pdf', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    changeInvoiceNfse({}, payload) {
      let formData = new FormData();
      formData.append('invoice_id', payload.invoice_id);
      formData.append('xml_file', payload.xml_file);
      formData.append('pdf_file', payload.pdf_file);
      formData.append('nfse', JSON.stringify(payload.nfse));
      return axios.post('/financial/billing-nfse/change-nfse', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    saveImportedCanceledNfseXmlPdf({}, payload) {
      let formData = new FormData();
      formData.append('invoice_id', payload.invoice_id);
      formData.append('xml_file', payload.xml_file);
      formData.append('pdf_file', payload.pdf_file);
      formData.append('reverse', Number(payload.reverse));
      formData.append('cancellation_date', payload.cancellation_date);
      return axios.post('/financial/billing-nfse/save-imported-canceled-xml-pdf', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    /**
     * Emitir notas fiscais pendentes
     *
     * @param {string} payload.range.start yyyy-mm-dd required
     * @param {string} payload.range.end yyyy-mm-dd required
     * @param {array} payload.invoice_ids [int, int, ...] nullable
     */
    issuePedingNfses ({}, payload) {
      return axios.put(`financial/billing-nfse/issue-nfses/pending`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    /**
     *
     * @param {integer} payload.msCompanyId
     * @param {file} payload.file
     */
    linkNfseLogo({}, payload) {
      const endpoint = `ms-company/${payload.msCompanyId}/link-logo+enotas`;
      const headers = { headers: { 'Content-Type': 'multipart/form-data' } };
      const formData = new FormData();
      formData.append('file', payload.file);
      return axios.post(endpoint, formData, headers)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        });
    },
    getOperationSourcesByNfseMsCompanyId({}, nfseMsCompanyId) {
      return axios.get(`api/operation-sources/${nfseMsCompanyId}+migrate-ms`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getSpecialTaxRulesByNfseMsCompanyId({}, nfseMsCompanyId) {
      return axios.get(`api/special-tax-rules/${nfseMsCompanyId}+migrate-ms`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    createMigrateNfse ({}, payload) {
      return axios.post(`api/nfses+migrate-ms`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    cancelMigrateNfse ({}, payload) {
      return axios.delete(`api/nfses/api-nfse-id/${payload.nfse_external_id.trim()}+migrate-ms`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
