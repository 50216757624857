import * as types from './mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = '/commercial/customer-construction/constructions'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET_CUSTOMER] (state, payload){
      state.items = payload
    },
    [types.PUSH_CUSTOMER] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW_CUSTOMER] (state, payload){
      state.item = payload
    },
    [types.DESTROY_CUSTOMER] (state, uuid){
      destroy(state,uuid)
    },
    [types.UPDATE_CUSTOMER] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    fetchItems({commit}) {
      return axios.get(`${endPoint}`)
        .then(({data}) =>{
          commit(types.SET_CUSTOMER, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, payload) {
      return axios.post(`${endPoint}`, payload)
        .then(({data}) =>{
          commit(types.PUSH_CUSTOMER, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, payload) {
      return axios.put(`${endPoint}/${payload.uuid}`, payload)
        .then(({data}) =>{
          commit(types.UPDATE_CUSTOMER, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.SHOW_CUSTOMER, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, uuid) {
      return axios.delete(`${endPoint}/${uuid}`)
        .then(({data}) =>{
          commit(types.DESTROY_CUSTOMER, uuid)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateAddress({commit}, payload) {
      return axios.put(`${endPoint}/addresses/${payload.uuid}`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    showAddress({commit, dispatch}, uuid) {
      return axios.get(`${endPoint}/addresses/${uuid}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    addAddress({commit, state}, payload) {
      return axios.post(`${endPoint}/addresses`, payload)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroyAddress({commit, dispatch}, uuid) {
      return axios.delete(`${endPoint}/addresses/${uuid}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getByCustomerConstruction({commit, state}, params) {
      return axios.get(setUrlWithNextPage(`${endPoint}/list/${params.uuid}`, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getConstructionsByCustomerConstruction({commit}, params) {
      return axios.get(`${endPoint}/get-constructions/${params.uuid}`)
        .then(({data}) =>{
          commit(types.SET_CUSTOMER, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getLastConstruction({commit}, params) {
      return axios.get(`${endPoint}/last-constructions`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getConstructionByNameAndCustomerUuid({commit, dispatch}, params) {
      return axios.get(`${endPoint}/by-name-customer-uuid/${params.construction_name}/${params.customer_construction_uuid}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getConstructionsWithoutContractByCustomerConstruction(_, params) {
      return axios.get(`${endPoint}/without-contract/${params.customer_construction_uuid}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    updateCustomerConstructionUUIDs({_}, construction_uuids) {
      return axios.put(`${endPoint}/update-customer-construction-uuids`, construction_uuids)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
