import * as types from './mutation_types'
import {destroy, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import axios from 'axios'
const endPoint = 'operational/stock-balance/'
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.DESTROY] (state, id){
      destroy(state,id)
    },
  },
  actions: {
    fetchItems({commit}, params) {
      return axios.get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({state}, params) {
      return axios.get(setUrlWithNextPage(endPoint + 'index', params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
