import * as types from "./mutation_types";
import axios from "axios";
const endPoint = "/financial/electronic-bank-statement";
export default {
  namespaced: true,
  state: {
    items: [],
  },
  getters: {
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
  },
  actions: {
    fetch({ commit }, payload) {
      return axios
        .get(endPoint, { params: payload })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit }, payload) {
      return axios
        .post(endPoint, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    revalidate(_, payload) {
      return axios
        .post(`${endPoint}/revalidate`, payload)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
